import React from 'react';
import { ProductSliderFields } from '../../../utils/exclusive/types.js';
import CFEntry from '../../../utils/universal/contentful/CFEntry.js';
import { Carousel } from '../../universal/Carousel/Carousel.js';

export interface ProductSliderCTProps {
  data: CFEntry<ProductSliderFields>;
}

const ProductSliderCT = ({ data }: ProductSliderCTProps) => {
  const products = data.entries<ProductSliderFields>('products');
  const items = products.data
    .map((product) => ({
      asset: product.assets('media').data[0],
      // caption: product.field<string>('description'),
      action: {
        label: product.field<string>('name'),
        link: `/products/${product.field('id')}`,
      },
    }))
    .filter((item) => Boolean(item.asset));
  return <Carousel items={items} />;
};

export default ProductSliderCT;
