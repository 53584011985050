import React from 'react';
import { styled, css } from 'styled-components';
import { lighten } from 'polished';
import LinkScrollReset from './LinkScrollReset.js';

const HeadingWrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin: auto;
  z-index: 1;
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 3rem;
  margin: 0;

  ${({ theme }) => css`
    color: ${lighten(0.25, theme.colors.AtomicTangerine)};
    transition: color ease-in-out 300ms;
  `}

  @media only screen and (min-width: 300px) {
    font-size: 4rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 5rem;
  }
`;

const Subheading = styled.h3`
  font-size: 1rem;
  margin: 0;

  @media only screen and (min-width: 300px) {
    font-size: 2rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 3rem;
  }
`;

const StyledLinkItem = styled<typeof LinkScrollReset, { image: string }>(
  LinkScrollReset,
)`
  ${({ image, theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    background: url(${image}) no-repeat center center;
    background-size: cover;
    color: ${theme.colors.WhiteSmoke};

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.RaisinBlack};
      transition: opacity ease-in-out 300ms;
      opacity: 0.7;
      z-index: 0;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          opacity: 0.6;
        }

        ${Heading} {
          color: ${lighten(0.15, theme.colors.AtomicTangerine)};
        }
      }
    }
  `}
`;

export interface LinkGridItemProps {
  heading: string;
  subheading?: string;
  image: string;
  link: string;
  className?: string;
}

const LinkGridItem = ({
  heading,
  subheading,
  image,
  link,
  className,
}: LinkGridItemProps) => {
  return (
    <StyledLinkItem to={link} image={image} className={className}>
      <HeadingWrapper>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
      </HeadingWrapper>
    </StyledLinkItem>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'main'
    'side'
    'footer';
  grid-template-rows: 2fr 2fr 2fr;
  grid-template-columns: 2fr;
  grid-gap: 15px;
  min-height: 300px;

  @media only screen and (min-width: 300px) {
    min-height: 400px;
  }

  @media only screen and (min-width: 400px) {
    min-height: 500px;
  }

  @media only screen and (min-width: 500px) {
    min-height: 600px;
  }

  @media only screen and (min-width: 600px) {
    min-height: 700px;
  }

  @media only screen and (min-width: 700px) {
    min-height: 800px;
  }

  @media only screen and (min-width: 800px) {
    grid-template-areas:
      'main side'
      'footer footer';
    grid-template-rows: 3fr 1.5fr;
    grid-template-columns: 3fr 1.5fr;
  }
`;

const MainGridItem = styled(LinkGridItem)``;

const SideGridItem = styled(LinkGridItem)``;

const FooterGridItem = styled(LinkGridItem)`
  grid-area: footer;
`;

export interface LinkGridProps {
  main: LinkGridItemProps;
  side: LinkGridItemProps;
  footer: LinkGridItemProps;
}

const LinkGrid = ({ main, side, footer }: LinkGridProps) => {
  return (
    <Wrapper>
      <MainGridItem {...main} />
      <SideGridItem {...side} />
      <FooterGridItem {...footer} />
    </Wrapper>
  );
};

export default LinkGrid;
