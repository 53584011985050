import React, { ReactNode, useEffect } from 'react';
import { styled, css } from 'styled-components';
import useMeasure from 'react-use-measure';
import { Theme } from '../../../../styles/exclusive/theme.js';
import MenuSideBarContent from './MenuSideBarContent/index.js';
import useMenuContext from '../context/useMenuContext.js';
import { Helmet } from '../../Helmet.js';
import {
  DEFAULT_MENU_SIDE_BAR_WIDTH,
  MINIMUM_LARGE_SCREEN_SIZE,
} from '../constants';

const OuterWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
`;

const MainContentWrapper = styled.div<{ isSideBarActive: boolean }>`
  transition: width 500ms ease-in-out;

  ${({ isSideBarActive }) => {
    return css`
      width: 100%;

      @media only screen and (min-width: ${MINIMUM_LARGE_SCREEN_SIZE}px) {
        width: ${isSideBarActive
          ? `calc(100% - ${DEFAULT_MENU_SIDE_BAR_WIDTH}px)`
          : '100%'};
      }
    `;
  }}
`;

const SideBarWrapper = styled.div<{ active: boolean; theme: Theme }>`
  position: absolute;
  max-width: 100%;
  transition: width 500ms ease-in-out;
  overflow: hidden;
  z-index: 100;
  right: 0;
  top: 0;
  bottom: 0;

  ${({ active, theme }) => {
    return css`
      background-color: ${theme.colors.RaisinBlack};
      width: ${active ? '100%' : 0};

      @media only screen and (min-width: ${MINIMUM_LARGE_SCREEN_SIZE}px) {
        position: relative;
        left: auto;
        top: auto;
        bottom: auto;
        width: ${active ? `${DEFAULT_MENU_SIDE_BAR_WIDTH}px` : 0};
      }
    `;
  }}
`;

const SideBarFixedContainer = styled.div<{ theme: Theme; active: boolean }>`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  transition:
    width 500ms ease-in-out,
    opacity,
    border-left 300ms ease-in-out;
  overflow: auto;

  ${({ theme, active }) => {
    return css`
      background-color: ${theme.colors.RaisinBlack};
      opacity: ${active ? 1 : 0};
      width: ${active ? '100%' : 0};
      border: ${active ? '5px' : 0} solid
        ${active ? theme.colors.AlloyOrange : 'transparent'};

      @media only screen and (min-width: ${MINIMUM_LARGE_SCREEN_SIZE}px) {
        border: 0;
        border-left: ${active ? '5px' : 0} solid
          ${active ? theme.colors.AlloyOrange : 'transparent'};
        width: ${active ? `${DEFAULT_MENU_SIDE_BAR_WIDTH}px` : 0};
      }
    `;
  }}
`;

export interface MenuSideBarProps {
  children: ReactNode;
}

const MenuSideBar = ({ children }: MenuSideBarProps) => {
  const [sidebarWrapperRef, { width: currentSidebarWrapperWidth }] =
    useMeasure();
  const {
    isMenuSidebarOpen,
    setMenuSidebarWidth,
    isLargeScreen,
    testIdGenerator,
  } = useMenuContext();

  useEffect(() => {
    setMenuSidebarWidth(currentSidebarWrapperWidth);
  }, [currentSidebarWrapperWidth]);

  return (
    <OuterWrapper>
      <Helmet>
        <body
          className={
            isMenuSidebarOpen && !isLargeScreen
              ? 'disable-scrolling'
              : undefined
          }
        />
      </Helmet>
      <MainContentWrapper
        isSideBarActive={isMenuSidebarOpen}
        style={{
          pointerEvents: isMenuSidebarOpen && !isLargeScreen ? 'none' : 'auto',
        }}
      >
        {children}
      </MainContentWrapper>
      <SideBarWrapper
        active={isMenuSidebarOpen}
        ref={sidebarWrapperRef}
        data-testid={testIdGenerator('side-menu')}
      >
        <SideBarFixedContainer active={isMenuSidebarOpen}>
          <MenuSideBarContent />
        </SideBarFixedContainer>
      </SideBarWrapper>
    </OuterWrapper>
  );
};

export default MenuSideBar;
