import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { listProductsDispatcher } from '../../data/products/dispatchers.js';
import {
  ProductsStoreState,
  defaultListResult,
} from '../../data/products/reducer.js';
import { AppDispatch, RootState } from '../../data/store.js';
import {
  createFilterKey,
  parseProductsOptionsFromLocation,
} from '../../utils/exclusive/products.js';
import { ProductEntry } from '../../utils/exclusive/types.js';
import useCFEntry from './useCFEntry.js';

export const PAGE_LIMIT = 100;

const productListSelector = createSelector(
  [
    (state: RootState, search) => state.products,
    (state: RootState, search) => search,
  ],
  ({ products, order }: ProductsStoreState, search: string) => {
    const filterKey = createFilterKey({ search });
    const { ids, lastFetchedPage, nextPage } =
      order[filterKey]?.list ?? defaultListResult();

    return {
      products: ids.map((id) => products[id]),
      lastFetchedPage,
      nextPage,
    };
  },
);

export interface SelectorResult {
  lastFetchedPage: number;
  nextPage?: number;
  products: ProductEntry[];
}

const useProductListData = () => {
  const location = useLocation();
  const { page, search } = parseProductsOptionsFromLocation(location);
  const dispatch = useDispatch() as AppDispatch;
  const createCfEntry = useCFEntry();
  const { products, lastFetchedPage, nextPage } = useSelector<
    RootState,
    SelectorResult
  >((state: RootState) => productListSelector(state, search));

  useEffect(() => {
    if (page > lastFetchedPage && nextPage) {
      listProductsDispatcher({
        startPageNo: lastFetchedPage + 1,
        endPageNo: page,
        search,
      })(dispatch);
    }
  }, [page, lastFetchedPage, nextPage, search, dispatch]);

  const endPage = page < lastFetchedPage ? page : lastFetchedPage;
  const endIndex = endPage * PAGE_LIMIT;

  return {
    page,
    products: products
      .slice(0, endIndex)
      .map((product) => createCfEntry(product)),
    nextPage: page < lastFetchedPage ? page + 1 : nextPage,
    search,
  };
};

export default useProductListData;
