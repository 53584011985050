import 'styled-components';
import colors from './colors.js';

const theme = {
  colors,
  window: {
    width: -1,
    height: -1,
  },
};

export type Theme = typeof theme;

declare module 'styled-components' {
  export type DefaultTheme = Theme;
}

export default theme;
