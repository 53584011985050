import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { ProductEntry } from '../../utils/exclusive/types.js';
import callDataAPI from '../../utils/universal/data/callDataAPI.js';
import { RootState } from '../store.js';

export interface FetchProductsByPageParams {
  startPageNo?: number;
  endPageNo?: number;
  search?: string;
  releasedAfter?: string;
  releasedBefore?: string;
}
export interface FetchProductsByPageResult {
  items: ProductEntry[];
  search: string;
  releasedAfter?: string;
  releasedBefore?: string;
  page: number;
  nextPage?: number;
}

export const listProducts: AsyncThunkPayloadCreator<
  FetchProductsByPageResult,
  FetchProductsByPageParams,
  { state: RootState }
> = ({
  startPageNo = 1,
  endPageNo = startPageNo,
  search,
  releasedAfter,
  releasedBefore,
}) => {
  return callDataAPI(`/products`, {
    page: `${startPageNo}-${endPageNo}`,
    search,
    releasedAfter,
    releasedBefore,
  });
};

export const fetchProductById = (
  productId: string,
): Promise<{ item: ProductEntry }> => {
  return callDataAPI(`/products/${productId}`);
};
