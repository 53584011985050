import { styled, css } from 'styled-components';

const TextInput = styled.input.attrs({ type: 'text' })`
  display: block;
  background: none;
  border: none;
  outline: none;
  font-size: 1.5rem;
  padding: 5px 0;
  border-radius: 0;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors.RoseEbony};
    border-bottom: 1px solid ${theme.colors.AlloyOrange};

    &::placeholder {
      color: ${theme.colors.RoseEbony};
    }

    &:focus {
      border-bottom: 1px solid ${theme.colors.AtomicTangerine};
    }
  `}
`;

export default TextInput;
