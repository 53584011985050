import { parseISO, format } from 'date-fns';

export const testIdGen = (testId: string) => `cfw-${testId}`;

export const formatDate = (dateString: string | undefined, unknown: string) => {
  if (!dateString) {
    return unknown;
  }

  const date = parseISO(dateString);

  return format(date, 'd MMM y');
};
