import React, { Children, ReactNode, forwardRef } from 'react';
import { styled } from 'styled-components';
import useMeasure from 'react-use-measure';
import mergeRefs from 'merge-refs';

export interface ImageGridProps {
  children: ReactNode;
  minColumnWidth: number;
  gap: number;
  maxColumnCount?: number;
  matchItemCount?: boolean;
  className?: string;
  testId?: string;
}

const Wrapper = styled.div`
  display: grid;
`;

export interface CalculateColumnCountParams {
  containerWidth: number;
  minColumnWidth: number;
  maxColumnCount?: number;
  matchItemCount?: boolean;
  itemCount: number;
  gap: number;
}

const calculateColumnCount = ({
  containerWidth,
  minColumnWidth,
  maxColumnCount,
  matchItemCount,
  itemCount,
  gap,
}: CalculateColumnCountParams) => {
  let columnCount: number;

  if (containerWidth < minColumnWidth * 2 + gap) {
    columnCount = 1;
  } else {
    const columnWidthWithGap = minColumnWidth + gap;
    columnCount = Math.floor(containerWidth / columnWidthWithGap);
  }

  if (maxColumnCount && columnCount > maxColumnCount) {
    columnCount = maxColumnCount;
  }

  if (matchItemCount && columnCount > itemCount) {
    columnCount = itemCount;
  }

  return columnCount;
};

const ImageGrid = forwardRef<HTMLElement, ImageGridProps>(
  (
    {
      children,
      minColumnWidth,
      maxColumnCount,
      matchItemCount,
      gap,
      className,
      testId,
    }: ImageGridProps,
    ref,
  ) => {
    const [elRef, { width: containerWidth }] = useMeasure();
    const items = Children.toArray(children);
    const columnCount = calculateColumnCount({
      containerWidth,
      minColumnWidth,
      maxColumnCount,
      matchItemCount,
      gap,
      itemCount: items.length,
    });

    return (
      <Wrapper
        ref={mergeRefs(ref, elRef) as any}
        data-testid={testId}
        style={{
          gap,
          gridTemplateColumns: `repeat(${columnCount}, minmax(0, 1fr))`,
        }}
        className={className}
      >
        {items}
      </Wrapper>
    );
  },
);

export default ImageGrid;
