import React, { forwardRef } from 'react';
import { styled, css } from 'styled-components';
import useFitText from 'use-fit-text';
import useImgLoad from '../../hooks/universal/useImgLoad.js';
import { Heading } from './Heading.js';

export interface BannerBackgroundProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
}

export const BannerBackground = styled(
  forwardRef(({ image, ...props }: BannerBackgroundProps, ref) => (
    <div ref={ref as any} {...props} />
  )),
)`
  ${({ image, theme }) => css`
    position: relative;
    background: url('${image}') no-repeat center center;
    transition: background 300ms ease-in-out;
    background-size: cover;
    min-height: 300px;
    font-size: 15rem;
    color: ${theme.colors.WhiteSmoke};

    @media only screen and (min-width: 300px) {
      min-height: 400px;
    }

    @media only screen and (min-width: 400px) {
      min-height: 500px;
    }

    @media only screen and (min-width: 500px) {
      min-height: 600px;
    }

    @media only screen and (min-width: 600px) {
      min-height: 700px;
    }

    @media only screen and (min-width: 700px) {
      min-height: 800px;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      background-color: ${theme.colors.RaisinBlack};
      opacity: 0.5;
    }
  `}
`;

export const TextWrapper = styled.div`
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(Heading)`
  padding: 10px;
`;

export const Subtitle = styled(Heading)`
  padding: 10px;
  padding-top: 50px;
`;

export interface BannerProps {
  image: string;
  title: string;
  subtitle?: string;
}

const Banner = ({ image, title, subtitle }: BannerProps) => {
  const src = useImgLoad(image, 2000);
  // const { fontSize: titleFontSize, ref: fitTextRef } = useFitText();
  return (
    <BannerBackground
      image={src}
      // ref={fitTextRef}
      // style={{ fontSize: titleFontSize }}
    >
      <TextWrapper>
        {/* @ts-ignore */}
        <Title level={1} size={5} margin="auto auto 0 auto">
          {title}
        </Title>
        {subtitle && (
          <Subtitle level={2} size={3} margin="0 auto auto auto">
            {subtitle}
          </Subtitle>
        )}
      </TextWrapper>
    </BannerBackground>
  );
};

export default Banner;
