import { lighten } from 'polished';
import React, { ReactNode } from 'react';
import { styled, css } from 'styled-components';

export interface StyledListItemProps {
  active: boolean;
}

const StyledListItem = styled.li<StyledListItemProps>`
  white-space: nowrap;

  & > a,
  & > button {
    font-size: 2.5rem;
    cursor: pointer;
    padding: 0 10px;
    width: 100%;
  }

  ${({ theme }) => css`
    & > a,
    & > button {
      transition: background-color 300ms ease-in-out;
      border: 3px solid ${theme.colors.AlloyOrange};
      border-left: 10px solid ${theme.colors.AlloyOrange};
      border-right: 10px solid ${theme.colors.AlloyOrange};
      color: ${lighten(0.5, theme.colors.AlloyOrange)};
      text-align: left;
    }

    & svg {
      transition: fill 300ms ease-in-out;
      fill: ${lighten(0.5, theme.colors.AlloyOrange)};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & > a,
        & > button {
          background-color: ${theme.colors.AlloyOrange};
        }
      }
    }
  `};

  ${({ active, theme }) => {
    if (active) {
      return css`
        & > a,
        & > button {
          border: 3px solid ${lighten(0.3, theme.colors.AtomicTangerine)};
          border-left: 10px solid ${lighten(0.3, theme.colors.AtomicTangerine)};
          border-right: 10px solid ${lighten(0.3, theme.colors.AtomicTangerine)};
          color: ${lighten(0.5, theme.colors.AlloyOrange)};
        }

        & svg {
          fill: ${lighten(0.5, theme.colors.AlloyOrange)};
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & > a,
            & > button {
              background-color: ${lighten(0.3, theme.colors.AtomicTangerine)};
              color: ${theme.colors.AlloyOrange};
            }

            & svg {
              fill: ${theme.colors.AlloyOrange};
            }
          }
        }
      `;
    }
    return '';
  }}
`;

export interface MenuSideBarListItemProps {
  active: boolean;
  children: ReactNode;
}

const MenuSideBarListItem = ({
  children,
  active,
}: MenuSideBarListItemProps) => {
  return <StyledListItem active={active}>{children}</StyledListItem>;
};

export default MenuSideBarListItem;
