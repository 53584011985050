import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBoolean, useMediaQuery } from 'usehooks-ts';
import {
  areURLPathAndQSTheSame,
  parseURLFromPath,
} from '../../../../utils/universal/data/url.js';
import { DEFAULT_PROVIDER_PROPS, menuContext } from './menuContext.js';
import { MenuContextProviderProps } from '../types.js';
import { MINIMUM_LARGE_SCREEN_SIZE } from '../constants.js';

const { Provider } = menuContext;

export interface MenuProviderProps extends Partial<MenuContextProviderProps> {
  sideMenuGoBackButtonLabel: string;
  children?: ReactNode;
}

const MenuProvider = ({ children, ...props }: MenuProviderProps) => {
  const contextValueAdditions = { ...DEFAULT_PROVIDER_PROPS, ...props };
  const location = useLocation();
  const isLargeScreen = useMediaQuery(
    `(min-width: ${MINIMUM_LARGE_SCREEN_SIZE}px)`,
  );
  const { value: isMenuSidebarOpen, toggle: toggleMenuSidebarOpen } =
    useBoolean(false);
  const [menuSidebarWidth, setMenuSidebarWidth] = useState(0);
  const [
    mainContentFixedElementWidthOffset,
    setMainContentFixedElementWidthOffset,
  ] = useState(0);

  useEffect(() => {
    if (isLargeScreen) {
      setMainContentFixedElementWidthOffset(menuSidebarWidth);
    } else {
      setMainContentFixedElementWidthOffset(0);
    }
  }, [menuSidebarWidth, isLargeScreen]);

  return (
    <Provider
      value={{
        isMenuSidebarOpen,
        toggleMenuSidebarOpen,
        menuSidebarWidth,
        setMenuSidebarWidth,
        mainContentFixedElementWidthOffset,
        isLargeScreen,
        isMenuItemActive: (to: string) =>
          to.startsWith('/')
            ? areURLPathAndQSTheSame(
                parseURLFromPath(to),
                parseURLFromPath(location.pathname + location.search),
                ['page'],
              )
            : false,
        ...contextValueAdditions,
      }}
    >
      {children}
    </Provider>
  );
};

export default MenuProvider;
