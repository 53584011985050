import React from 'react';
import { css, styled } from 'styled-components';
import CFAsset from '../../../utils/universal/contentful/CFAsset.js';
import { LinkButton } from '../Form/Button.js';
import useImgLoad from '../../../hooks/universal/useImgLoad.js';

const CarouselFigure = styled.figure<Pick<CarouselItemProps, 'active'>>`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? 'visible' : 'none')};
  transition: opacity 300ms ease-in-out;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  user-select: none;
`;

const CarouselImage = styled.img<{ hasCaption: boolean }>`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  max-width: 100%;
  z-index: 1;
  pointer-events: none;

  ${({ theme }) => css`
    box-shadow: 0 0 20px 5px ${theme.colors.RaisinBlack};
    border: 20px solid ${theme.colors.RaisinBlack};
    border-top-width: 50px;
    border-bottom-width: 50px;
  `}

  ${({ hasCaption }) => {
    if (hasCaption) {
      return css`
        max-height: calc(100% - 50px);

        @media only screen and (max-width: 700px) {
          max-height: calc(100% - 100px);
        }
      `;
    }

    return css`
      bottom: 0;
      max-height: 100%;
    `;
  }}
`;

const CarouselCaption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  font-size: 2.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.WhiteSmoke};
  background-color: ${({ theme }) => theme.colors.RaisinBlack};
  padding: 10px;
  margin: auto -70px 0 -70px;

  @media only screen and (max-width: 999px) {
    font-size: 2rem;
  }
`;

const CarouselAction = styled(LinkButton)`
  position: absolute;
  margin: auto;
  bottom: 50px;
  left: auto;
  right: auto;
  z-index: 3;
  padding: 20px 40px;
  font-size: 3rem;

  @media only screen and (max-width: 999px) {
    padding: 10px 20px;
    font-size: 2rem;
  }
`;

export interface CarouselItemData {
  asset: CFAsset;
  overrideAlt?: string;
  caption?: string;
  action?: {
    label: string;
    link: string;
  };
}

export interface CarouselItemProps {
  active: boolean;
  data: CarouselItemData;
  testid?: string;
}

export const CarouselItem = ({
  active,
  data: { asset, overrideAlt, caption, action },
  testid,
}: CarouselItemProps) => {
  const src = useImgLoad(asset.file().url, 1000);
  return (
    <CarouselFigure active={active} data-testid={testid}>
      <CarouselImage
        key={asset.assetId()}
        src={src}
        alt={overrideAlt ?? asset.alt()}
        data-testid="carousel-image"
        hasCaption={Boolean(caption)}
      />
      {caption && <CarouselCaption>{caption}</CarouselCaption>}
      {action && (
        <CarouselAction to={action.link}>{action.label}</CarouselAction>
      )}
    </CarouselFigure>
  );
};
