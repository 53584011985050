import React from 'react';
import { styled, css } from 'styled-components';
import { Document as RichTextDocument } from '@contentful/rich-text-types';
import { Carousel } from '../../universal/Carousel/Carousel.js';
import RichText from '../../universal/RichText/index.js';
import { Helmet } from '../../universal/Helmet.js';
import { LinkButton } from '../../universal/Form/Button.js';
import { Heading } from '../../universal/Heading.js';
import {
  useProductData,
  useNeighboringProductData,
} from '../../../hooks/exclusive/useProductData.js';
import Section from '../../universal/Section.js';
import FixedWidthWrapper from '../../universal/FixedWidthWrapper.js';
import staticText from '../../../utils/exclusive/staticText.js';
import StaticText from '../../universal/StaticText/StaticText.js';
import { testIdGen } from '../../../utils/exclusive/misc.js';
import { Document, Paragraph } from '../../universal/RichText/NodesAndMarks.js';
import ImageGrid from '../../universal/ImageGrid/index.js';
import ImageGridItem from '../../universal/ImageGrid/ImageGridItem.js';
import ArrowButton from '../../universal/icon-buttons/ArrowButton.js';
import { useMediaQuery } from 'usehooks-ts';
import SectionBackground from '../../universal/SectionBackground.js';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 1fr;
`;

const ProductHeading = styled(Heading)`
  padding: 10px 0;
  ${({ theme }) => css`
    color: ${theme.colors.RoseEbony};
  `}
`;

const AllProductsButton = styled(LinkButton)``;

const ImageGridOuterWrapper = styled.div`
  display: grid;
`;

const ImageGridInnerWrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const DescriptionWrapper = styled.div`
  /* ${({ theme }) => css`
    min-height: 100px;
    background-color: ${theme.colors.RaisinBlack};
    color: ${theme.colors.WhiteSmoke};
    padding: 10px;
  `}; */
`;

const Product = () => {
  const product = useProductData();
  const previousProduct = useNeighboringProductData('releasedAfter');
  const nextProduct = useNeighboringProductData('releasedBefore');
  const isLargeScreen = useMediaQuery('(min-width: 600px)');

  if (!product) {
    return null;
  }

  const name = product.field<string>('name');
  const description = product.field<string>('description');
  const longDescription = product.field<RichTextDocument>('longDescription');
  const pictures = product.assets('media');
  const id = product.field<string>('id');
  const metaImage = pictures.data?.[0]?.file()?.url;

  return (
    <Section>
      <Wrapper data-testid={testIdGen('product')} key={id}>
        <Helmet>
          <title>
            {staticText({
              path: 'PRODUCTS.TITLE',
              params: {
                name,
              },
            })}
          </title>
          <meta name="description" content={description} />
          <meta property="og:title" content={name} />
          <meta property="og:description" content={description} />
          {metaImage ? <meta property="og:image" content={metaImage} /> : null}
          <link
            rel="canonical"
            href={`https://cascadiafinewoodcraft.com/products/${id}`}
          />
        </Helmet>

        <FixedWidthWrapper margin="0">
          <ProductHeading
            level={1}
            size={isLargeScreen ? 4 : 3}
            data-testid={testIdGen('product-heading')}
          >
            {name}
          </ProductHeading>
        </FixedWidthWrapper>

        <FixedWidthWrapper margin="0">
          {pictures.data.length ? (
            <Carousel
              items={pictures.data.map((picture) => ({
                asset: picture,
                overrideAlt: name,
              }))}
              testId={testIdGen('product-gallery')}
            />
          ) : null}
        </FixedWidthWrapper>

        <SectionBackground>
          <FixedWidthWrapper margin="0">
            <DescriptionWrapper
              data-testid={testIdGen('product-long-description')}
            >
              {longDescription ? (
                <RichText document={longDescription} />
              ) : (
                <Document>
                  <Paragraph>{description}</Paragraph>
                </Document>
              )}
            </DescriptionWrapper>
          </FixedWidthWrapper>
        </SectionBackground>

        <FixedWidthWrapper margin="0">
          {previousProduct || nextProduct ? (
            <ImageGridOuterWrapper>
              <ImageGridInnerWrapper
                style={{ maxWidth: previousProduct && nextProduct ? 750 : 500 }}
              >
                <ImageGrid
                  gap={15}
                  minColumnWidth={150}
                  maxColumnCount={3}
                  testId={testIdGen('product-neighbors-grid')}
                  matchItemCount
                >
                  {previousProduct && (
                    <ImageGridItem
                      key={previousProduct.field<string>('id')}
                      title={<ArrowButton direction="left" size={70} />}
                      image={
                        previousProduct.assets('media').data?.[0]?.file()
                          ?.url ?? ''
                      }
                      url={`/products/${previousProduct.field<string>('id')}`}
                      testId={testIdGen(
                        `grid-item-${previousProduct.field<string>('id')}`,
                      )}
                    />
                  )}
                  {isLargeScreen && (previousProduct || nextProduct) && (
                    <ImageGridItem
                      key={product.field<string>('id')}
                      title={<div>{product.field<string>('name')}</div>}
                      image={
                        product.assets('media').data?.[0]?.file()?.url ?? ''
                      }
                      testId={testIdGen(
                        `grid-item-${product.field<string>('id')}`,
                      )}
                    />
                  )}
                  {nextProduct && (
                    <ImageGridItem
                      key={nextProduct.field<string>('id')}
                      title={<ArrowButton direction="right" size={70} />}
                      image={
                        nextProduct.assets('media').data?.[0]?.file()?.url ?? ''
                      }
                      url={`/products/${nextProduct.field<string>('id')}`}
                      testId={testIdGen(
                        `grid-item-${nextProduct.field<string>('id')}`,
                      )}
                    />
                  )}
                </ImageGrid>
              </ImageGridInnerWrapper>
            </ImageGridOuterWrapper>
          ) : null}
        </FixedWidthWrapper>

        <FixedWidthWrapper>
          <AllProductsButton
            to="/products"
            date-testid={testIdGen('product-all-products-button')}
          >
            <StaticText path="PRODUCTS.DETAIL.SEE_ALL_PRODUCTS_BUTTON" />
          </AllProductsButton>
        </FixedWidthWrapper>
      </Wrapper>
    </Section>
  );
};

export default Product;
