import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store.js';
import CFEntry from '../../utils/universal/contentful/CFEntry.js';
import {
  MenuFields,
  MenuItemFields,
  SubMenuFields,
} from '../../utils/exclusive/types.js';
import { MenuProviderProps } from '../../components/universal/Menu/context/MenuProvider.js';
import {
  TopMenuItems,
  SideMenuItems,
  MenuItem,
  ParentMenuItem,
} from '../../components/universal/Menu/types.js';
import { fetchMenusDispatcher } from '../../data/menus/dispatchers.js';
import CFList from '../../utils/universal/contentful/CFList.js';
import staticText from '../../utils/exclusive/staticText.js';
import { testIdGen } from '../../utils/exclusive/misc.js';

const MAIN_MENU_ID = 'main-menu';

const SIDE_MENU_ID = 'side-menu';

const TOP_MENU_ID = 'top-menu';

const isMenuItem = (
  item: CFEntry<MenuItemFields> | CFEntry<SubMenuFields>,
): item is CFEntry<MenuItemFields> => Boolean(item.field<string>('to'));

const processMenu = (
  items: CFList<CFEntry<MenuItemFields> | CFEntry<SubMenuFields>>,
  allowSubMenus: boolean = false,
): TopMenuItems | SideMenuItems => {
  return items.data
    .map(
      (
        item: CFEntry<MenuItemFields> | CFEntry<SubMenuFields>,
      ): MenuItem | ParentMenuItem | undefined => {
        const id = item.contentId();
        const label = item.field<string>('label');

        if (isMenuItem(item)) {
          return {
            id,
            label,
            to: item.field<string>('to'),
          } as MenuItem;
        }

        if (!allowSubMenus) {
          return undefined;
        }

        const items = (item as CFEntry<SubMenuFields>).entries<
          MenuItemFields | SubMenuFields
        >('items') as CFList<CFEntry<MenuItemFields> | CFEntry<SubMenuFields>>;

        return {
          id,
          label,
          items: processMenu(items),
        } as ParentMenuItem;
      },
    )
    .filter((item) => Boolean(item)) as TopMenuItems | SideMenuItems;
};

const useMenus = () => {
  const dispatch = useDispatch() as AppDispatch;
  const { loaded, menuProviderProps } = useSelector<
    RootState,
    { menuProviderProps: MenuProviderProps; loaded: boolean }
  >(
    (state) => {
      const menuProviderProps: MenuProviderProps = {
        topMenuItems: [],
        sideMenuItems: [],
        alwaysShowSideMenuExpandButton: false,
        minimumTopMenuVisibleItems: 0,
        sideMenuGoBackButtonLabel: staticText({
          path: 'HEADER.SIDE_MENU_GO_BACK_BUTTON',
        }),
        testIdGenerator: testIdGen,
      };
      const menus = state.menus.items.map(
        (item) => new CFEntry<MenuFields>(item),
      );
      const loaded = state.menus.loaded;

      for (const menu of menus) {
        const id = menu.field<string>('id');
        const items = menu.entries<MenuItemFields | SubMenuFields>(
          'items',
        ) as CFList<CFEntry<MenuItemFields> | CFEntry<SubMenuFields>>;

        if ([SIDE_MENU_ID, MAIN_MENU_ID].includes(id)) {
          menuProviderProps.sideMenuItems = processMenu(items, true);
          menuProviderProps.alwaysShowSideMenuExpandButton =
            menu.field<{ alwaysShowSideMenuExpandButton?: boolean }>('metadata')
              ?.alwaysShowSideMenuExpandButton ?? false;
        }

        if ([TOP_MENU_ID, MAIN_MENU_ID].includes(id)) {
          menuProviderProps.topMenuItems = processMenu(items) as TopMenuItems;
          menuProviderProps.minimumTopMenuVisibleItems =
            menu.field<{ minimumTopMenuVisibleItems?: number }>('metadata')
              ?.minimumTopMenuVisibleItems ?? 0;
        }
      }

      return {
        menuProviderProps,
        loaded,
      };
    },
    (oldVal, newVal) => JSON.stringify(oldVal) === JSON.stringify(newVal),
  );

  useEffect(() => {
    if (!loaded) {
      fetchMenusDispatcher()(dispatch);
    }
  }, [loaded]);

  return menuProviderProps;
};

export default useMenus;
