import React, { MouseEvent, ReactElement, ReactNode, useCallback } from 'react';
import { styled, css } from 'styled-components';
import { rgba } from 'polished';
import { Theme } from '../../../styles/exclusive/theme.js';
import useImgLoad from '../../../hooks/universal/useImgLoad.js';
import useEntryTrigger from '../../../hooks/universal/useEntryTrigger.js';
import LinkScrollReset from '../LinkScrollReset.js';

const sharedItemStyles = css`
  transition:
    width,
    height 300ms ease-in-out;
`;

const LinkItem = styled(LinkScrollReset)`
  ${sharedItemStyles}
  text-decoration: none;
`;

const PlainItem = styled.div`
  ${sharedItemStyles}
`;

interface InnerWrapperProps {
  image?: string;
  active: boolean;
  children: ReactNode;
  theme: Theme;
}

const InnerWrapper = styled(
  ({ image, active, ...props }: InnerWrapperProps) => <div {...props} />,
)`
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  opacity: ${({ active }: InnerWrapperProps) => (active ? 1 : 0)};

  ${({ image, theme }: InnerWrapperProps) => {
    if (image) {
      return css`
        background: url(${image}) no-repeat center center;
        background-size: cover;
      `;
    }
    return css`
      border: 3px solid ${theme.colors.AlloyOrange};
    `;
  }}
`;

interface OverlapProps {
  hoverEffect: boolean;
}

const Overlay = styled(({ hoverEffect, ...props }: OverlapProps) => {
  return <div {...props} />;
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => rgba(theme.colors.RaisinBlack, 0.5)};
  transition: all 500ms ease-in-out;

  ${({ hoverEffect }: OverlapProps) => {
    if (hoverEffect) {
      return css`
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) =>
              rgba(theme.colors.AtomicTangerine, 0.5)};
          }
        }
      `;
    }
    return '';
  }}
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.WhiteSmoke};
  user-select: none;
  pointer-events: none;
  display: block;
  z-index: 2;
  text-align: center;
  margin: auto;
  padding: 10px;
  overflow-wrap: break-word;
  max-width: 100%;
`;

interface ImageWrapperProps {
  image: string;
  children: ReactNode;
}

const ImageWrapper = ({ image, children }: ImageWrapperProps) => {
  const src = useImgLoad(image, 1000);
  const [active] = useEntryTrigger(src);

  return (
    <InnerWrapper image={src} active={active}>
      {children}
    </InnerWrapper>
  );
};

const PlainWrapper = ({ children }: Omit<ImageWrapperProps, 'image'>) => {
  const [active] = useEntryTrigger(true);
  return <InnerWrapper active={active}>{children}</InnerWrapper>;
};

export type ImageGridItemOnClickHandlerParams = {
  event: MouseEvent;
  id?: string;
};

export type ImageGridItemOnClickHandler = (
  params: ImageGridItemOnClickHandlerParams,
) => void;

export interface ImageGridItemProps {
  url?: string;
  id?: string;
  image?: string;
  title: ReactNode;
  onClick?: ImageGridItemOnClickHandler;
  testId?: string;
}

const ImageGridItem = ({
  image,
  url,
  id,
  title,
  onClick: _onClick,
  testId,
}: ImageGridItemProps) => {
  const Wrapper = image ? ImageWrapper : PlainWrapper;
  const wrapperProps = (image ? { image } : {}) as any;
  const onClick = useCallback(
    (event: MouseEvent) => _onClick?.({ id, event }),
    [id, _onClick],
  );
  const Item = (url ? LinkItem : PlainItem) as unknown as (
    props: any,
  ) => ReactElement;
  const itemProps = url ? { to: url } : ({} as any);
  return (
    <Item data-testid={testId} onClick={onClick} {...itemProps}>
      <Wrapper {...wrapperProps}>
        <Overlay hoverEffect={Boolean(url)} />
        <Title>{title}</Title>
      </Wrapper>
    </Item>
  );
};

export default ImageGridItem;
