import { StaticTextConstants } from '../../components/universal/StaticText/staticTextContext.js';
import staticTextExtractor, {
  StaticTextExtractorParams,
} from '../../components/universal/StaticText/staticTextExtractor.js';
import TEXT from '../../constants/text.js';

export interface StaticTextParams
  extends Omit<StaticTextExtractorParams, 'constants'> {}

const staticText = (params: StaticTextParams) => {
  return staticTextExtractor({
    constants: TEXT as StaticTextConstants,
    ...params,
  });
};

export default staticText;
