import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactMethodFields } from '../../utils/exclusive/types.js';
import CFEntry from '../../utils/universal/contentful/CFEntry.js';
import { AppDispatch, RootState } from '../store.js';
import { fetchSettingsDispatcher } from './dispatchers.js';

export const useSettings = () => {
  const dispatch = useDispatch() as AppDispatch;
  const { loaded, location, phone, email } = useSelector<
    RootState,
    {
      loaded: boolean;
      location: string;
      phone: string;
      email: string;
    }
  >(
    (state) => {
      const { loaded, location, contacts } = state.settings;
      const { phone, email } = contacts.reduce(
        (acc, contactData) => {
          const contact = new CFEntry<ContactMethodFields>(contactData);
          const type = contact.field<string>('type');
          const value = contact.field<string>('value');

          if (type === 'phone') {
            acc.phone = value;
          }

          if (type === 'email') {
            acc.email = value;
          }

          return acc;
        },
        { phone: '', email: '' },
      );
      return {
        loaded,
        location,
        phone,
        email,
      };
    },
    (oldVal, newVal) => JSON.stringify(oldVal) === JSON.stringify(newVal),
  );

  useEffect(() => {
    if (!loaded) {
      fetchSettingsDispatcher()(dispatch);
    }
  }, [loaded]);

  return {
    loaded,
    location,
    phone,
    email,
  };
};
