import { styled } from 'styled-components';

const Main = styled.main`
  display: grid;
  gap: 15px;
  grid-template-rows: 1fr;
  padding-bottom: 450px;

  @media only screen and (min-width: 700px) {
    padding-bottom: 200px;
  }
`;

export default Main;
