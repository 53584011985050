// Source: https://coolors.co/272727-c56820-f5f5f5-ffa288-6a4641

const colors = {
  RaisinBlack: '#272727',
  RoseEbony: '#6A4641',
  WhiteSmoke: '#F5F5F5',
  AlloyOrange: '#C56820',
  AtomicTangerine: '#FFA288',
};

export type Colors = typeof colors;

export default colors;
