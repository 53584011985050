import React, { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

export type HeadingRange = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingSpacing = 'top' | 'bottom' | 'both' | 'none';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: HeadingRange;
  size?: HeadingRange;
  spacing?: HeadingSpacing;
  margin?: string;
}

const sizeMap = {
  1: 4,
  2: 3.5,
  3: 3,
  4: 2.5,
  5: 2,
  6: 1.5,
};

export const Heading = forwardRef(
  (
    { level = 1, size, spacing = 'none', margin, ...props }: HeadingProps,
    ref: ForwardedRef<unknown>,
  ) => {
    let DynamicHeading = `h${level}`;

    return (
      <DynamicHeading
        /* @ts-ignore */
        ref={ref}
        style={{
          fontSize: `${size ? size : sizeMap[level]}rem`,
          fontWeight: 600,
          margin: margin
            ? margin
            : `${getTopSpacing(spacing)} 0 ${getBottomSpacing(spacing)} 0`,
        }}
        {...props}
      />
    );
  },
);

type HasSpacing = (spacing: HeadingSpacing) => boolean;

const hasTopSpacing = (spacing: HeadingSpacing) =>
  ['top', 'both'].includes(spacing);

const hasBottomSpacing = (spacing: HeadingSpacing) =>
  ['bottom', 'both'].includes(spacing);

const getConditionalSpacing =
  (condition: HasSpacing) => (spacing: HeadingSpacing) =>
    condition(spacing) ? '20px' : '0';

const getTopSpacing = getConditionalSpacing(hasTopSpacing);

const getBottomSpacing = getConditionalSpacing(hasBottomSpacing);
