import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  MouseEvent,
} from 'react';
import { Link, LinkProps, To, useLocation } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

const isLocalPath = (to: To) =>
  typeof to === 'string' ? to.startsWith('/') : true;

const LinkScrollReset = forwardRef(
  ({ onClick: _onClick, ...props }: LinkProps, ref: Ref<HTMLAnchorElement>) => {
    const { to } = props;
    const location = useLocation();
    const { value: shouldScrollUpOnClick, setValue: setShouldScrollUpOnClick } =
      useBoolean(false);

    useEffect(() => {
      let linkPathname: string | undefined;

      if (typeof to === 'string') {
        if (to.startsWith('/')) {
          linkPathname = new URL(to, window.location.origin).pathname;
        } else {
          return;
        }
      } else {
        linkPathname = to.pathname;
      }

      if (linkPathname !== location.pathname) {
        setShouldScrollUpOnClick(true);
      } else {
        setShouldScrollUpOnClick(false);
      }
    }, [to, location.pathname, setShouldScrollUpOnClick]);

    const onClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        _onClick?.(event);
        if (!event.defaultPrevented && shouldScrollUpOnClick) {
          requestAnimationFrame(() => {
            window.scroll(0, 0);
          });
        }
      },
      [shouldScrollUpOnClick, _onClick],
    );

    return (
      <Link
        {...props}
        target={isLocalPath(to) ? undefined : '_blank'}
        onClick={onClick}
        ref={ref}
      />
    );
  },
);

export default LinkScrollReset;
