import {
  ContentfulAsset,
  DefaultFields,
  ExtendedEntry,
} from '../../exclusive/types.js';
import CFAsset from './CFAsset.js';
import CFData from './CFData.js';
import CFList from './CFList.js';

export default class CFEntry<
  Fields extends DefaultFields = DefaultFields,
> extends CFData<ExtendedEntry<string, DefaultFields>, CFEntry<Fields>> {
  fields<F = Fields>(): F {
    return this.data.fields as unknown as F;
  }

  field<Field>(fieldName: string): Field {
    return this.get(['fields', fieldName]) as Field;
  }

  entry<Fields extends DefaultFields>(fieldName: string) {
    return new CFEntry<Fields>(
      this.field<ExtendedEntry<string, DefaultFields>>(fieldName),
      this.options,
    );
  }

  entries<Fields extends DefaultFields>(
    fieldName: string,
  ): CFList<CFEntry<Fields>> {
    return new CFList<CFEntry<Fields>>(
      (this.field<ExtendedEntry<string, DefaultFields>[]>(fieldName) ?? []).map(
        (entry) => new CFEntry(entry, this.options),
      ),
      this.options,
    );
  }

  asset(fieldName: string) {
    return new CFAsset(this.field<ContentfulAsset>(fieldName), this.options);
  }

  assets(fieldName: string) {
    return new CFList<CFAsset>(
      (this.field<ContentfulAsset[]>(fieldName) ?? []).map(
        (entry) => new CFAsset(entry),
      ),
      this.options,
    );
  }

  contentType(): string {
    return this.get('sys.contentType.sys.id');
  }

  contentId(): string {
    return this.get('sys.id');
  }
}
