import { styled, css } from 'styled-components';
import LinkScrollReset from '../LinkScrollReset.js';

const sharedButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2rem;
  cursor: pointer;
  ${({ theme }) => {
    return css`
      color: ${theme.colors.WhiteSmoke};
      background-color: ${theme.colors.AlloyOrange};
      padding: 10px 20px;
      transition:
        color,
        background-color 300ms ease-in-out;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: ${theme.colors.RoseEbony};
        }
      }
    `;
  }}
`;

const sharedAnchorAndLinkStyles = css`
  text-decoration: none;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  ${sharedButtonStyles}
  background: none;
`;

export const AnchorButton = styled.a`
  ${sharedButtonStyles}
  ${sharedAnchorAndLinkStyles}
`;

export const LinkButton = styled(LinkScrollReset)`
  ${sharedButtonStyles}
  ${sharedAnchorAndLinkStyles}
`;
