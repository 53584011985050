import React from 'react';
import {
  PageEntry,
  ProductEntry,
  TwoColumnImageTextSectionFields,
} from '../../../utils/exclusive/types.js';
import CFEntry from '../../../utils/universal/contentful/CFEntry.js';
import { TwoColumnImageTextSection } from '../../universal/TwoColumnImageTextSection.js';
import { HeadingRange } from '../../universal/Heading.js';

export interface TwoColumnImageTextSectionCTProps {
  data: CFEntry<TwoColumnImageTextSectionFields>;
}

const isPage = (link: ProductEntry | PageEntry): link is PageEntry => {
  return link.sys.contentType.sys.id === 'page';
};

const TwoColumnImageTextSectionCT = ({
  data,
}: TwoColumnImageTextSectionCTProps) => {
  const { title, titleHeadingLevel, contents, link, linkLabel, image } =
    data.fields();
  return (
    <TwoColumnImageTextSection
      title={title}
      titleHeadingLevel={titleHeadingLevel as HeadingRange}
      contents={contents}
      linkLabel={linkLabel}
      link={isPage(link) ? link.fields.path : `/products/${link.fields.id}`}
      image={image?.fields?.file?.url}
    />
  );
};

export default TwoColumnImageTextSectionCT;
