import React, { ReactElement } from 'react';
import { css, styled } from 'styled-components';
import ArrowButton, { ArrowButtonProps } from '../icon-buttons/ArrowButton.js';

export interface CarouselNavButtonProps
  extends Omit<ArrowButtonProps, 'direction'> {
  hasCaption: boolean;
}

export type CarouselNavButton = (props: CarouselNavButtonProps) => ReactElement;

export const PrevButton = styled(
  ({ hasCaption, ...props }: CarouselNavButtonProps) => {
    return <ArrowButton direction="left" size={70} {...props} />;
  },
)`
  @media only screen and (max-width: 999px) {
    position: absolute;
    z-index: 5;
    left: 0;
  }

  ${({ hasCaption }) => {
    if (hasCaption) {
      return css`
        margin-bottom: 50px;

        @media only screen and (max-width: 700px) {
          margin-bottom: 100px;
        }
      `;
    }

    return '';
  }}
` as CarouselNavButton;

export const NextButton = styled(
  ({ hasCaption, ...props }: CarouselNavButtonProps) => {
    return <ArrowButton direction="right" size={70} {...props} />;
  },
)`
  @media only screen and (max-width: 999px) {
    position: absolute;
    z-index: 5;
    right: 0;
  }

  ${({ hasCaption }) => {
    if (hasCaption) {
      return css`
        margin-bottom: 50px;

        @media only screen and (max-width: 700px) {
          margin-bottom: 100px;
        }
      `;
    }

    return '';
  }}
` as CarouselNavButton;
