import {
  parseLocationQS,
  parseQS,
  QSInput,
  stringifyQS,
} from '../universal/data/url.js';

export const parseProductsQueryOptions = (init: QSInput) => {
  const instance = parseQS(init);
  const _page = instance.get('page') ?? '1';
  let page = Number.parseInt(String(_page));
  if (!Number.isInteger(page) || page < 1) {
    page = 1;
  }
  return {
    page,
    search: instance.get('search') ?? '',
  };
};

export interface StringifyProductsQueryOptionsProps {
  page?: number;
  search: string;
}

export const stringifyProductsQueryOptions = ({
  page,
  search,
}: StringifyProductsQueryOptionsProps) =>
  stringifyQS({
    page,
    search,
  });

export const parseProductsOptionsFromLocation = (
  ...params: Parameters<typeof parseLocationQS>
) => parseProductsQueryOptions(parseLocationQS(...params));

export interface CreateFilterKeyProps {
  search?: string;
  releasedBefore?: string;
  releasedAfter?: string;
}

export const createFilterKey = ({
  search,
  releasedBefore,
  releasedAfter,
}: CreateFilterKeyProps = {}) =>
  new URLSearchParams(
    Object.fromEntries(
      Object.entries({ search, releasedBefore, releasedAfter }).filter(
        ([, value]) => Boolean(value),
      ) as [string, string][],
    ),
  ).toString();
