import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSettings } from './apis.js';
import { ContactMethodEntry } from '../../utils/exclusive/types.js';

export const fetchSettingsAction = createAsyncThunk(
  'settings/fetchSettings',
  fetchSettings,
);

export interface SettingsStoreState {
  loaded: boolean;
  location: string;
  contacts: ContactMethodEntry[];
}

const initialState: SettingsStoreState = {
  loaded: false,
  location: '',
  contacts: [],
};

const { reducer } = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsAction.fulfilled, (state, action) => {
      const { item } = action.payload;
      state.loaded = true;
      state.location = item.fields.location;
      state.contacts = item.fields.contacts ?? [];
    });
  },
});

export const settingsReducer = reducer;
