import React from 'react';
import { BannerFields } from '../../../utils/exclusive/types.js';
import CFEntry from '../../../utils/universal/contentful/CFEntry.js';
import Banner from '../../universal/Banner.js';

export interface BannerCTProps {
  data: CFEntry<BannerFields>;
}

const BannerCT = ({ data }: BannerCTProps) => {
  return (
    <Banner
      image={data.asset('image').file().url}
      title={data.field('title')}
      subtitle={data.field('subtitle')}
    />
  );
};

export default BannerCT;
