import React from 'react';
import { css, styled } from 'styled-components';

export interface SectionBackgroundProps
  extends React.HTMLAttributes<HTMLElement> {
  coverPadded?: boolean;
}

const SectionBackground = styled(
  ({ coverPadded = false, ...props }: SectionBackgroundProps) => (
    <div {...props} />
  ),
)`
  background-color: ${({ theme }) => theme.colors.RaisinBlack};
  color: ${({ theme }) => theme.colors.WhiteSmoke};
  padding: 15px 0;

  ${({ coverPadded }) =>
    coverPadded &&
    css`
      margin-left: -15px;
      margin-right: -15px;
    `}
`;

export default SectionBackground;
