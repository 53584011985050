import React, { useContext } from 'react';
import { Path } from 'object-path';
import staticTextContext, {
  StaticTextGeneratorParams,
} from './staticTextContext.js';
import staticTextExtractor from './staticTextExtractor.js';

export interface StaticTextProps {
  path: Path;
  params?: StaticTextGeneratorParams;
}

const StaticText = ({ path, params }: StaticTextProps) => {
  const { constants } = useContext(staticTextContext);
  return (
    <>
      {staticTextExtractor({
        constants,
        path,
        params,
      })}
    </>
  );
};

export default StaticText;
