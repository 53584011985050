import React from 'react';
import { Outlet } from 'react-router-dom';
import usePageData from '../../../hooks/exclusive/usePageData.js';
import { PageFields } from '../../../utils/exclusive/types.js';
import { Helmet } from '../../universal/Helmet.js';
import PageHeader from '../../universal/PageHeader.js';
import PageWrapper from './PageWrapper.js';

const PADDED_TEMPLATE = 'Padded' as const;

const CMSPage = () => {
  const data = usePageData();

  if (!data) {
    return null;
  }

  const { title, heading, description, path, template, includePageHeader } =
    data.fields<PageFields>() ?? {};
  const image = data.asset('image')?.file()?.url ?? '';

  return (
    <>
      <Helmet>
        {path !== '/' ? <title>{title}</title> : <></>}
        <meta name="description" content={description} />
        <meta property="og:title" content={heading} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <link
          rel="canonical"
          href={`https://cascadiafinewoodcraft.com${path !== '/' ? path : ''}`}
        />
      </Helmet>
      {includePageHeader && <PageHeader heading={heading} image={image} />}
      <PageWrapper padded={template === PADDED_TEMPLATE}>
        <Outlet />
      </PageWrapper>
    </>
  );
};

export default CMSPage;
