import React from 'react';
import { SectionLinkFields } from '../../../utils/exclusive/types.js';
import CFEntry from '../../../utils/universal/contentful/CFEntry.js';
import SectionLink from '../../universal/SectionLink.js';

export interface SectionLinkCTProps {
  data: CFEntry<SectionLinkFields>;
}

const SectionLinkCT = ({ data }: SectionLinkCTProps) => {
  return (
    <SectionLink
      link={data.field<string>('link')}
      text={data.field<string>('text')}
      image={data.asset('image').file().url}
    />
  );
};

export default SectionLinkCT;
