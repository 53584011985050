import BannerCT from './BannerCT.js';
import LinkGridCT from './LinkGridCT.js';
import ProductSliderCT from './ProductSliderCT.js';
import RichTextSectionCT from './RichTextSectionCT.js';
import SectionLinkCT from './SectionLinkCT.js';
import TwoColumnImageTextSectionCT from './TwoColumnImageTextSectionCT.js';

const contentTypes = {
  sectionLink: SectionLinkCT,
  banner: BannerCT,
  linkGrid: LinkGridCT,
  richTextSection: RichTextSectionCT,
  twoColumnImageTextSection: TwoColumnImageTextSectionCT,
  productSlider: ProductSliderCT,
} as unknown as Record<string, (args: Record<string, unknown>) => JSX.Element>;

export default contentTypes;
