import { useEffect, useState } from 'react';

const useImgLoad = (src: string, width?: number) => {
  const [loaded, setLoaded] = useState(Boolean(globalThis.global));
  useEffect(() => {
    setLoaded(false);
    const img = new Image();
    img.onload = function () {
      requestAnimationFrame(() => {
        setLoaded(true);
      });
    };
    img.src = src;
  }, [src]);
  return loaded ? `${src}${width ? `?w=${width}` : ''}` : '';
};

export default useImgLoad;
