import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PageEntry } from '../../utils/exclusive/types.js';
import { fetchPageByPath } from './apis.js';

export const fetchPageByPathAction = createAsyncThunk(
  'pages/fetchPageData',
  fetchPageByPath,
);

export interface PagesStoreState {
  [pagePath: string]: PageEntry;
}

const initialState = {} as PagesStoreState;

const { reducer } = createSlice({
  name: 'pages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPageByPathAction.fulfilled, (state, action) => {
      const { item } = action.payload;
      const path = item.fields.path;
      state[path] = item;
    });
  },
});

export const pagesReducer = reducer;
