import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import BrowserApp from './components/exclusive/app/BrowserApp.js';

const container = document.getElementById('app-wrapper');

if (!container) {
  throw new Error('App container not found.');
}

const run = async () => {
  if (MODE === 'production') {
    hydrateRoot(container, <BrowserApp />);
  } else {
    const root = createRoot(container);
    root.render(<BrowserApp />);
  }
};

run();
