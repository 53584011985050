import { DefaultFields, ExtendedEntry } from '../../utils/exclusive/types.js';
import CFEntry from '../../utils/universal/contentful/CFEntry.js';

const useCFEntry = () => {
  return <Fields extends DefaultFields = DefaultFields>(
    data: ExtendedEntry<string, DefaultFields>,
  ) => {
    return new CFEntry<Fields>(data);
  };
};

export default useCFEntry;
