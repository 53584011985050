import { styled, css } from 'styled-components';
import LinkScrollReset from '../LinkScrollReset.js';

const sharedNodeStyles = css`
  margin: 15px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const sharedListStyles = css`
  padding-inline-start: 25px;
`;

const sharedLinkStyles = css`
  display: inline-block;
  text-decoration: none;
  padding-bottom: 1px;
  transition: all 300ms ease-in-out;
  color: ${({ theme }) => theme.colors.AtomicTangerine};

  &:after {
    display: block;
    content: '';
    border-bottom: 1px solid ${({ theme }) => theme.colors.AtomicTangerine};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover:after {
      transform: scaleX(1);
    }
  }

  &:focus:after {
    transform: scaleX(1);
  }
`;

export const Document = styled.div`
  font-size: 2rem;
  line-height: 3rem;
`;

export const Paragraph = styled.p`
  ${sharedNodeStyles}
`;

export const H1 = styled.h1`
  ${sharedNodeStyles}
`;

export const H2 = styled.h2`
  ${sharedNodeStyles}
`;

export const H3 = styled.h3`
  ${sharedNodeStyles}
`;

export const H4 = styled.h4`
  ${sharedNodeStyles}
`;

export const H5 = styled.h5`
  ${sharedNodeStyles}
`;

export const H6 = styled.h6`
  ${sharedNodeStyles}
`;

export const UL = styled.ul`
  ${sharedNodeStyles}
  ${sharedListStyles}
`;

export const OL = styled.ol`
  ${sharedNodeStyles}
  ${sharedListStyles}
`;

export const Anchor = styled.a`
  ${sharedLinkStyles}
`;

export const StyledLink = styled(LinkScrollReset)`
  ${sharedLinkStyles}
`;

export const Underline = styled.u`
  text-decoration: none;
  border-bottom: 1px solid;
  padding-bottom: 1px;
  line-height: 2.4rem;
`;
