import objectPath, { Path } from 'object-path';

const { get, set, insert, del, has } = objectPath;

export interface CFDataOptions {}

export type CFDataOptionsInput = Partial<CFDataOptions>;

export default class CFData<Data extends object, SubType> {
  data: Data;
  options: CFDataOptions;

  constructor(data: Data, options: CFDataOptionsInput = {}) {
    this.data = data;
    this.options = options;
  }

  get<T>(path: Path): T {
    return get(this.data, path);
  }

  set<T>(path: Path, value: any, doNotReplace?: boolean): T {
    return set(this.data, path, value, doNotReplace);
  }

  insert(path: Path, value: any, at?: number) {
    return insert(this.data, path, value, at);
  }

  del(path: Path) {
    return del(this.data, path);
  }

  has(path: Path) {
    return has(this.data, path);
  }

  toJSON() {
    return this.data;
  }
}
