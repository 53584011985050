import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  parseProductsOptionsFromLocation,
  stringifyProductsQueryOptions,
} from '../../utils/exclusive/products.js';
import { useDebounceCallback } from 'usehooks-ts';

const useProductGridFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const debouncedNavigate = useDebounceCallback(
    navigate,
    500,
  ) as unknown as typeof navigate;
  const { search: currentSearch } = parseProductsOptionsFromLocation(location);
  const [search, setSearch] = useState(currentSearch);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      switch (name) {
        case 'product-grid-search':
          setSearch(value);
          break;
      }
    },
    [location],
  );

  useEffect(() => {
    if (search !== currentSearch) {
      setSearch(currentSearch);
    }
  }, [currentSearch]);

  useEffect(() => {
    if (search !== currentSearch) {
      debouncedNavigate(
        `/products${stringifyProductsQueryOptions({
          search,
        })}`,
      );
    }
  }, [search]);

  return {
    onChange,
    search,
  };
};

export default useProductGridFilter;
