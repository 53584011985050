import { stringifyQS } from './url.js';

const getHeaders = (): Record<string, string> => {
  let headers = {};

  if (!globalThis.window) {
    if (
      process.env.CONTENTFUL_IS_PREVIEW === 'true' ||
      process.env.CONTENTFUL_ENVIRONMENT !== 'master'
    ) {
      headers = {
        ...headers,
        'x-preview-env-access-code': String(process.env.PREVIEW_ACCESS_KEY),
      };
    }
  }

  return headers;
};

const callDataAPI = async (
  path: string,
  requestOptions: Record<string, any> = {},
) => {
  let response: Response | undefined;
  let text: string | undefined;
  const queryString = stringifyQS(requestOptions);
  const endpoint = `${process.env.DATA_API_BASE_URL}/data${path}${queryString}`;
  const options = {
    headers: getHeaders(),
    credentials: 'same-origin' as const,
  };
  try {
    response = await fetch(endpoint, options);
    text = await response.text();
    return JSON.parse(text);
  } catch (error) {
    console.error({
      message: 'Unexpected data API response',
      dataAPI: {
        endpoint,
        options,
      },
      response: {
        text,
        statusCode: response?.status,
      },
    });
    throw error;
  }
};

export default callDataAPI;
