import React from 'react';
import { styled, css } from 'styled-components';
import { Heading } from './Heading.js';
import useImgLoad from '../../hooks/universal/useImgLoad.js';

const Header = styled.header<{ image: string }>`
  ${({ image, theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    background: url(${image}) no-repeat center center;
    background-size: cover;
    color: ${theme.colors.WhiteSmoke};

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.RaisinBlack};
      opacity: 0.5;
      z-index: 0;
    }

    @media only screen and (min-width: 500px) {
      min-height: 200px;
    }

    @media only screen and (min-width: 800px) {
      min-height: 300px;
    }
  `}
`;

const StyledHeading = styled(Heading)`
  padding: 20px;
  z-index: 1;
`;

export interface PageHeaderProps {
  heading: string;
  image: string;
}

const PageHeader = ({ heading, image }: PageHeaderProps) => {
  const src = useImgLoad(image, 2000);
  return (
    <Header image={src}>
      <StyledHeading level={1} size={5} margin="auto">
        {heading}
      </StyledHeading>
    </Header>
  );
};

export default PageHeader;
