import React, { ReactNode } from 'react';
import staticTextContext, { StaticTextConstants } from './staticTextContext.js';

const { Provider } = staticTextContext;

export interface StaticTextProviderProps {
  constants: StaticTextConstants;
  children: ReactNode;
}

const StaticTextProvider = ({
  constants,
  children,
}: StaticTextProviderProps) => {
  return <Provider value={{ constants }}>{children}</Provider>;
};

export default StaticTextProvider;
