import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import useMenuContext from './Menu/context/useMenuContext.js';

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 1000px;
  margin: auto;
`;

export interface FixedWidthWrapperProps {
  children?: ReactNode;
  margin?: string;
}

const FixedWidthWrapper = ({
  children,
  margin = 'auto auto 15px auto',
}: FixedWidthWrapperProps) => {
  const { menuSidebarWidth } = useMenuContext();
  return (
    <OuterWrapper style={{ margin }}>
      <InnerWrapper
        style={{
          maxWidth: `calc(100vw - ${menuSidebarWidth}px - 30px)`,
        }}
      >
        {children}
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default FixedWidthWrapper;
