import React from 'react';
import { css, styled } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme.js';
import LinkScrollReset from '../../LinkScrollReset.js';
import useMenuTopBar from '../TopBar/useMenuTopBar.js';
import { MenuItem, RealLinkProps } from '../types.js';

const OuterWrapper = styled.nav`
  grid-area: menu;
`;

const InnerWrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: 700px) {
    align-items: flex-end;
  }
`;

interface MenuTopBarButtonProps extends RealLinkProps {
  theme: Theme;
  active: boolean;
}

const FooterMenuItem = styled(
  ({ active, className, ...props }: MenuTopBarButtonProps) => (
    <li className={className}>
      <LinkScrollReset {...props} />
    </li>
  ),
)`
  white-space: nowrap;
  font-size: 2rem;
  padding: 0 10px;

  ${({ theme }: MenuTopBarButtonProps) => css<MenuTopBarButtonProps>`
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: border-bottom-color 300ms ease-in-out;

    & a {
      text-decoration: none;
      color: ${theme.colors.WhiteSmoke};
    }

    ${({ active }: MenuTopBarButtonProps) => {
      if (active) {
        return css`
          border-bottom-color: ${theme.colors.AlloyOrange};
        `;
      }
      return css``;
    }}
  `};
`;

const FooterMenu = () => {
  const { wrapperElementRef, topMenuItems, isMenuItemActive, testIdGenerator } =
    useMenuTopBar();

  return (
    <OuterWrapper data-testid={testIdGenerator('menu-bottom-bar')}>
      <InnerWrapper ref={wrapperElementRef}>
        {topMenuItems.map(({ label, to }: MenuItem) => (
          <FooterMenuItem key={label} to={to} active={isMenuItemActive(to)}>
            {label}
          </FooterMenuItem>
        ))}
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default FooterMenu;
