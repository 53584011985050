import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import useMenuContext from '../../context/useMenuContext.js';
import { ParentMenuItem, SideBarMenuParentNode } from '../../types.js';
import MenuSideBarHeader from './MenuSideBarHeader.js';
import MenuSideBarItems from './MenuSideBarItems.js';

const Wrapper = styled.div`
  display: grid;
  gap: 30px;
  padding: 15px 20px;
`;

const Nav = styled.nav`
  display: grid;
  gap: 20px;
`;

const MenuSideBarContent = () => {
  const { current: root } = useRef<SideBarMenuParentNode>({ id: 'Root' });
  const { toggleMenuSidebarOpen, sideMenuGoBackButtonLabel, testIdGenerator } =
    useMenuContext();
  const [parentPath, setParentPath] = useState<SideBarMenuParentNode[]>([root]);

  const resetMenu = () => setParentPath([root]);

  const onClose = () => {
    toggleMenuSidebarOpen();
    resetMenu();
  };

  return (
    <Wrapper>
      <MenuSideBarHeader
        {...{
          onClose,
          showBackButton: parentPath[parentPath.length - 1] !== root,
          onBackButtonClick: () => {
            setParentPath(parentPath.slice(0, parentPath.length - 1));
          },
          sideMenuGoBackButtonLabel,
          testIdGenerator,
        }}
      />
      <Nav>
        <MenuSideBarItems
          {...{
            root,
            onClose,
            resetMenu,
            openSubMenu: (item: ParentMenuItem) => () =>
              setParentPath([...parentPath, item]),
            isItemListVisible: (parent: SideBarMenuParentNode) =>
              parentPath[parentPath.length - 1] === parent,
          }}
        />
      </Nav>
    </Wrapper>
  );
};

export default MenuSideBarContent;
