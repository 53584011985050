import React from 'react';
import { styled, css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme.js';
import {
  DEFAULT_MENU_SIDE_BAR_WIDTH,
  TOP_BAR_MENU_ITEM_GAP,
} from '../constants.js';
import MenuExpandButton from './MenuExpandButton.js';
import { MenuItem, RealLinkProps } from '../types.js';
import useMenuTopBar from './useMenuTopBar.js';
import LinkScrollReset from '../../LinkScrollReset.js';

const OuterWrapper = styled.nav`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 30px;
  overflow: hidden;
  align-items: center;
  min-width: 50px;

  @media only screen and (min-width: ${DEFAULT_MENU_SIDE_BAR_WIDTH}px) {
    gap: 100px;
  }
`;

interface InnerWrapperProps {
  visible: boolean;
}

const InnerWrapper = styled.ul<InnerWrapperProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: ${TOP_BAR_MENU_ITEM_GAP}px;
  flex-wrap: nowrap;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;

  ${({ visible }) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
  `};
`;

interface MenuTopBarButtonProps extends RealLinkProps {
  theme: Theme;
  active: boolean;
  visible: boolean;
}

const MenuTopBarButton = styled(
  ({ active, visible, className, ...props }: MenuTopBarButtonProps) => (
    <li className={className}>
      <LinkScrollReset {...props} />
    </li>
  ),
)`
  white-space: nowrap;
  font-size: 2rem;
  padding: 0 10px;

  ${({ theme, visible }: MenuTopBarButtonProps) => css<MenuTopBarButtonProps>`
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: border-bottom-color 300ms ease-in-out;
    display: ${visible ? 'block' : 'none'};

    & a {
      text-decoration: none;
      color: ${theme.colors.WhiteSmoke};
    }

    ${({ active }: MenuTopBarButtonProps) => {
      if (active) {
        return css`
          border-bottom-color: ${theme.colors.AlloyOrange};
        `;
      }
      return css``;
    }}
  `};
`;

const MenuTopBar = () => {
  const {
    isTopBarMenuVisible,
    wrapperElementRef,
    isMenuItemVisible,
    isSideBarMenuExpandButtonVisible,
    topMenuItems,
    isMenuItemActive,
    testIdGenerator,
  } = useMenuTopBar();

  return (
    <OuterWrapper data-testid={testIdGenerator('menu-top-bar')}>
      <InnerWrapper ref={wrapperElementRef} visible={isTopBarMenuVisible}>
        {topMenuItems.map(({ label, to }: MenuItem, index: number) => (
          <MenuTopBarButton
            key={label}
            to={to}
            active={isMenuItemActive(to)}
            visible={isMenuItemVisible(index)}
          >
            {label}
          </MenuTopBarButton>
        ))}
      </InnerWrapper>
      {isSideBarMenuExpandButtonVisible && <MenuExpandButton />}
    </OuterWrapper>
  );
};

export default MenuTopBar;
