import React from 'react';
import { Route } from 'react-router-dom';
import {
  fetchProductByIdLoader,
  listProductsLoader,
} from '../../../data/products/loaders.js';
import { fetchPageByPathLoader } from '../../../data/pages/loaders.js';
import CMSPage from '../page/CMSPage.js';
import GenericPage from '../page/GenericPage.js';
import StaticPage from '../page/StaticPage.js';
import Product from '../products/Product.js';
import ProductGrid from '../products/ProductGrid.js';
import { mainLoader } from '../../../data/mainLoader.js';

const routesElement = (
  <Route>
    <Route path="/" element={<GenericPage />} loader={() => mainLoader}>
      <Route
        path="products/:productId"
        element={<Product />}
        loader={() => fetchProductByIdLoader}
      />
      <Route element={<CMSPage />} loader={() => fetchPageByPathLoader}>
        <Route
          path="products"
          element={<ProductGrid />}
          loader={() => listProductsLoader}
        />
        <Route path="*?" element={<StaticPage />} />
      </Route>
    </Route>
  </Route>
);

export default routesElement;
