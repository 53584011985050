import { parseProductsQueryOptions } from '../../utils/exclusive/products.js';
import { LoaderProps } from '../types.js';
import {
  fetchProductByIdDispatcher,
  listProductsDispatcher,
} from './dispatchers.js';

export const fetchProductByIdLoader = ({ match: { params } }: LoaderProps) =>
  fetchProductByIdDispatcher(String(params.productId));

export const listProductsLoader = ({ location: { query } }: LoaderProps) => {
  const { page: endPageNo, search } = parseProductsQueryOptions(query);
  return listProductsDispatcher({
    startPageNo: 1,
    endPageNo,
    search,
  });
};
