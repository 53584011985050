import React, { ReactElement } from 'react';
import { styled, css } from 'styled-components';
import { Theme } from '../../../styles/exclusive/theme.js';
import IconButton, { IconButtonProps } from '../IconButton.js';

export type ArrowDirection = 'left' | 'right' | 'down' | 'up';

export interface ArrowButtonProps extends Omit<IconButtonProps, 'children'> {
  direction: ArrowDirection;
}

export interface StyledArrowProps {
  direction: ArrowDirection;
  size?: string;
  className?: string;
  theme: Theme;
}

const ROTATIONS = {
  left: 180,
  right: 0,
  down: 90,
  up: -90,
};

export const ArrowIcon = styled(({ className }: StyledArrowProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 129 129"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    enableBackground="new 0 0 129 129"
    className={className}
  >
    <g>
      <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z" />
    </g>
  </svg>
))`
  fill: ${({ theme }: StyledArrowProps) => theme.colors.WhiteSmoke};
  transition: all 300ms ease-in-out;
  width: ${({ size }: StyledArrowProps) => size};
  height: ${({ size }: StyledArrowProps) => size};

  &:hover {
    fill: ${({ theme }: StyledArrowProps) => theme.colors.AtomicTangerine};
  }

  ${({ direction }: StyledArrowProps) => css`
    transform: rotate(${ROTATIONS[direction]}deg);
  `}
` as unknown as (props: any) => ReactElement;

const ArrowButton = ({ direction, ...props }: ArrowButtonProps) => {
  return (
    <IconButton {...props}>
      {() => <ArrowIcon direction={direction} />}
    </IconButton>
  );
};

export default ArrowButton;
