import React from 'react';
import { useRoutes } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { useWindowSize } from 'usehooks-ts';
import isPropValid from '@emotion/is-prop-valid';
import { StyledTarget } from 'styled-components/dist/types.js';
import TEXT from '../../../constants/text.js';
import routes from '../../../data/routes.js';
import useMenus from '../../../hooks/exclusive/useMenus.js';
import useReduxLocationSync from '../../../hooks/exclusive/useReduxLocationSync.js';
import useEntryTrigger from '../../../hooks/universal/useEntryTrigger.js';
import GlobalStyles from '../../../styles/exclusive/GlobalStyles.js';
import theme from '../../../styles/exclusive/theme.js';
import staticText from '../../../utils/exclusive/staticText.js';
import {
  Helmet,
  HelmetContext,
  HelmetProvider,
} from '../../universal/Helmet.js';
import Main from '../../universal/Main.js';
import MenuSideBar from '../../universal/Menu/SideBar/MenuSideBar.js';
import MenuProvider from '../../universal/Menu/context/MenuProvider.js';
import StaticTextProvider from '../../universal/StaticText/StaticTextProvider.js';
import { StaticTextConstants } from '../../universal/StaticText/staticTextContext.js';
import Footer from '../page/Footer.js';
import Header from '../page/Header.js';

export interface AppProps {
  helmetContext?: HelmetContext;
}

const invisibleStyles = `
  body {
    opacity: 0;
    visibility: hidden;
  }
`;

const visibleStyles = `
  body {
    opacity: 1;
    visibility: visible;
  }
`;

const App = ({ helmetContext }: AppProps) => {
  useReduxLocationSync();
  const windowSize = useWindowSize();
  const menuProviderProps = useMenus();
  const routesMatch = useRoutes(routes) as any;
  const [active] = useEntryTrigger(true, {
    timeout: 300,
    activeInSSR: false,
  });

  return (
    <HelmetProvider context={helmetContext}>
      <StyleSheetManager
        shouldForwardProp={(
          prop: string,
          elementToBeCreated: StyledTarget<'web'>,
        ) =>
          typeof elementToBeCreated === 'string' ? isPropValid(prop) : true
        }
      >
        <ThemeProvider
          theme={{
            ...theme,
            window: windowSize,
          }}
        >
          <MenuProvider {...menuProviderProps}>
            <StaticTextProvider constants={TEXT as StaticTextConstants}>
              <GlobalStyles />
              <Helmet
                defaultTitle={staticText({
                  path: 'TITLE',
                })}
                titleTemplate={staticText({
                  path: 'DEFAULT_TITLE',
                })}
                prioritizeSeoTags
              >
                <style>{active ? visibleStyles : invisibleStyles}</style>
                <noscript>{`<style>${visibleStyles}</style>`}</noscript>
              </Helmet>
              <MenuSideBar>
                <Header />
                <Main>{routesMatch}</Main>
                <Footer />
              </MenuSideBar>
            </StaticTextProvider>
          </MenuProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </HelmetProvider>
  );
};

export default App;
