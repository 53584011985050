import { useEffect, useRef, useState } from 'react';
import useMeasure from 'react-use-measure';
import { mergeRefs } from 'react-merge-refs';
import { useBoolean, useMediaQuery } from 'usehooks-ts';
import {
  DEFAULT_MENU_SIDE_BAR_WIDTH,
  TOP_BAR_MENU_ITEM_GAP,
} from '../constants.js';
import { useIsNode } from '../../../../utils/universal/env.js';
import useMenuContext from '../context/useMenuContext.js';
import { MenuContext } from '../types.js';

const getWidth = (element: HTMLElement) =>
  element.getBoundingClientRect().width;

export interface UseMenuTopBarResult extends MenuContext {
  isTopBarMenuVisible: boolean;
  isSideBarMenuExpandButtonVisible: boolean;
  wrapperElementRef: (
    instance: HTMLElement | SVGElement | null | undefined,
  ) => void;
  isMenuItemVisible: (index: number) => boolean;
  visibleItemCount: number;
}

const useMenuTopBar = (): UseMenuTopBarResult => {
  const isNode = useIsNode();
  const isLargeScreen = useMediaQuery(
    `(min-width: ${DEFAULT_MENU_SIDE_BAR_WIDTH}px)`,
  );
  const {
    value: isTopBarMenuVisible,
    setTrue: showTopBarMenu,
    setFalse: hideTopBarMenu,
    setValue: setTopBarMenuVisibility,
  } = useBoolean(isNode);
  const [itemWidths, setItemWidths] = useState<number[]>([]);
  const [visibleItemCount, setVisibleItemCount] = useState(0);
  const mainRef = useRef();
  const [measureRef, { width: wrapperWidth }] = useMeasure();
  const menuContext = useMenuContext();

  useEffect(() => {
    hideTopBarMenu();
  }, [menuContext.topMenuItems]);

  useEffect(() => {
    if (isTopBarMenuVisible || menuContext.isMenuSidebarOpen) {
      return;
    }

    const wrapper = mainRef.current as unknown as HTMLDivElement;
    const widthList: number[] = [];
    const menuItems = Array.from(wrapper.querySelectorAll('li'));

    for (const menuItem of menuItems) {
      const menuItemWidth = getWidth(menuItem);
      widthList.push(menuItemWidth);
    }

    setItemWidths(widthList);
    showTopBarMenu();
  }, [isTopBarMenuVisible, menuContext.isMenuSidebarOpen]);

  useEffect(() => {
    if (itemWidths.length === 0) {
      return;
    }

    let remainingWidth = wrapperWidth;
    let visibilityCounter = 0;

    for (const itemWidth of itemWidths) {
      remainingWidth -= itemWidth;

      if (remainingWidth >= 0) {
        visibilityCounter += 1;
      } else {
        break;
      }

      remainingWidth -= TOP_BAR_MENU_ITEM_GAP;
    }

    if (visibilityCounter < menuContext.minimumTopMenuVisibleItems) {
      visibilityCounter = 0;
    }

    setVisibleItemCount(visibilityCounter);
  }, [wrapperWidth, itemWidths]);

  useEffect(() => {
    if (itemWidths.length === 0) {
      return;
    }

    setTopBarMenuVisibility(!menuContext.isMenuSidebarOpen);
  }, [menuContext.isMenuSidebarOpen, itemWidths]);

  return {
    isTopBarMenuVisible,
    isSideBarMenuExpandButtonVisible:
      isTopBarMenuVisible &&
      (menuContext.alwaysShowSideMenuExpandButton ||
        visibleItemCount < itemWidths.length),
    wrapperElementRef: mergeRefs([measureRef, mainRef]),
    isMenuItemVisible: (index: number) =>
      isNode ||
      !isTopBarMenuVisible ||
      (isLargeScreen && index < visibleItemCount),
    visibleItemCount,
    ...menuContext,
  };
};

export default useMenuTopBar;
