import { DoublyLinkedList } from '@datastructures-js/linked-list';
import React, { useCallback, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { css, styled } from 'styled-components';
import staticText from '../../../utils/exclusive/staticText.js';
import { CarouselItem, CarouselItemData } from './CarouselItem.js';
import { NextButton, PrevButton } from './NavButtons.js';

const Wrapper = styled.div<{ centerContents: boolean }>`
  ${({ centerContents }) => css`
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;

    ${centerContents
      ? css`
          align-items: center;
          flex-direction: column;
        `
      : ''}
  `}
`;

const Background = styled.div<{ image: string }>`
  ${({ image }) => css`
    background: url('${image}') no-repeat center center;
  `}
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  filter: blur(10px);
`;

const CarouselImageWrapper = styled.div`
  position: relative;
  width: calc(100% - 140px);
  height: 100%;
  padding: 0 10px;
  height: 500px;
  margin: auto;

  @media only screen and (max-width: 999px) {
    max-width: 100%;
    width: 100%;
    padding: 0;
    /* height: 400px; */
  }

  /* @media only screen and (max-width: 800px) {
    height: 380px;
  }

  @media only screen and (max-width: 700px) {
    height: 350px;
  }

  @media only screen and (max-width: 600px) {
    height: 330px;
  }

  @media only screen and (max-width: 500px) {
    height: 300px;
  }

  @media only screen and (max-width: 400px) {
    height: 280px;
  }

  @media only screen and (max-width: 300px) {
    height: 250px;
  }

  @media only screen and (max-width: 200px) {
    height: 200px;
  } */
`;

export interface CarouselProps {
  items: CarouselItemData[];
  testId?: string;
}

export const Carousel = ({ items, testId }: CarouselProps) => {
  const assetList = useMemo(() => {
    const list = DoublyLinkedList.fromArray<CarouselItemData>(items);
    list.tail().setNext(list.head());
    list.head().setPrev(list.tail());
    return list;
  }, [items]);
  const [currentAsset, setCurrentAsset] = useState(assetList.head());
  const onLeftClick = useCallback(() => {
    setCurrentAsset(currentAsset.getPrev());
  }, [currentAsset]);
  const onRightClick = useCallback(() => {
    setCurrentAsset(currentAsset.getNext());
  }, [currentAsset]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onLeftClick(),
    onSwipedRight: () => onRightClick(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const currentAssetData = currentAsset.getValue() as CarouselItemData;

  return (
    <Wrapper
      centerContents={assetList.count() === 1}
      data-testid={testId}
      {...swipeHandlers}
    >
      <Background image={currentAssetData.asset.file().url} />
      {assetList.count() > 1 ? (
        <PrevButton
          data-testid="carousel-navigation-prev-button"
          onClick={onLeftClick}
          aria-label={staticText({
            path: 'PRODUCTS.DETAIL.CAROUSEL.PREV_BUTTON_ARIA_LABEL',
          })}
          hasCaption={Boolean(currentAssetData.caption)}
        />
      ) : null}
      <CarouselImageWrapper data-testid="carousel-image-wrapper">
        {items.map((item) => (
          <CarouselItem
            key={item.asset.assetId()}
            active={item.asset.assetId() === currentAssetData.asset.assetId()}
            testid="carousel-image"
            data={item}
          />
        ))}
      </CarouselImageWrapper>
      {assetList.count() > 1 ? (
        <NextButton
          data-testid="carousel-navigation-next-button"
          onClick={onRightClick}
          aria-label={staticText({
            path: 'PRODUCTS.DETAIL.CAROUSEL.NEXT_BUTTON_ARIA_LABEL',
          })}
          hasCaption={Boolean(currentAssetData.caption)}
        />
      ) : null}
    </Wrapper>
  );
};
