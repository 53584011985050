import React, { ReactElement } from 'react';
import SectionGenerator from '../../universal/SectionGenerator.js';
import contentTypes from '../contentTypes/index.js';
import usePageData from '../../../hooks/exclusive/usePageData.js';

const StaticPage = () => {
  const data = usePageData();

  if (!data) {
    return null;
  }

  const contents = data.entries('contents');

  return (
    <SectionGenerator>
      {
        contents.data
          .map((item) => {
            const contentTypeName = item.contentType();
            const contentId = item.contentId();
            const DynamicComponent = contentTypes[contentTypeName];
            if (!DynamicComponent) {
              return null;
            }
            return <DynamicComponent key={contentId} data={item} />;
          })
          .filter((element) => Boolean(element)) as ReactElement[]
      }
    </SectionGenerator>
  );
};

export default StaticPage;
