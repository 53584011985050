import { createContext } from 'react';
import { MenuContext } from '../types.js';

export const DEFAULT_PROVIDER_PROPS = {
  topMenuItems: [],
  sideMenuItems: [],
  alwaysShowSideMenuExpandButton: true,
  minimumTopMenuVisibleItems: 0,
  testIdGenerator: (testId: string) => testId,
};

export const DEFAULT_MENU_CONTEXT: MenuContext = {
  isMenuSidebarOpen: false,
  menuSidebarWidth: 0,
  mainContentFixedElementWidthOffset: 0,
  setMenuSidebarWidth: () => {},
  toggleMenuSidebarOpen: () => {},
  isMenuItemActive: () => false,
  isLargeScreen: false,
  sideMenuGoBackButtonLabel: '',
  ...DEFAULT_PROVIDER_PROPS,
};

export const menuContext = createContext(DEFAULT_MENU_CONTEXT);
