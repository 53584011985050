import { ContentfulAsset } from '../../exclusive/types.js';
import CFData from './CFData.js';

export default class CFAsset extends CFData<ContentfulAsset, CFAsset> {
  // TODO: use mixins to avoid repetition
  fields<F = ContentfulAsset['fields']>(): F {
    return this.data.fields as F;
  }

  field<Field>(fieldName: string | number): Field {
    return this.get(['fields', fieldName]) as Field;
  }

  assetId(): string {
    return this.get('sys.id');
  }

  title() {
    return this.field<string>('title');
  }

  description() {
    return this.field<string>('description') ?? '';
  }

  alt() {
    return this.description() || this.title();
  }

  file() {
    return this.field<ContentfulAsset['fields']['file']>('file');
  }
}
