import React, { HTMLAttributes } from 'react';
import { styled } from 'styled-components';
import { Theme } from '../../../../../styles/exclusive/theme.js';
import { ArrowIcon } from '../../../icon-buttons/ArrowButton.js';

export interface StyledButtonProps {
  theme: Theme;
}

const StyledButton = styled.button`
  display: flex;
  text-decoration: none;
  align-items: center;
  background: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  text-align: left;
  justify-content: space-between;
`;

const StyledText = styled.div``;

export interface MenuSideBarSubMenuExpandButtonProps
  extends HTMLAttributes<HTMLButtonElement> {
  textPosition: 'before' | 'after';
}

const MenuSideBarSubMenuToggleButton = ({
  children,
  textPosition,
  ...props
}: MenuSideBarSubMenuExpandButtonProps) => {
  return (
    <StyledButton {...props}>
      {textPosition === 'before' && <ArrowIcon direction="left" size="30px" />}
      <StyledText>{children}</StyledText>
      {textPosition === 'after' && <ArrowIcon direction="right" size="30px" />}
    </StyledButton>
  );
};

export default MenuSideBarSubMenuToggleButton;
