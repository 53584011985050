import React from 'react';
import { styled } from 'styled-components';
import { TwoColumnImageTextSectionFields } from '../../utils/exclusive/types.js';
import RichText from './RichText/index.js';
import { Heading, HeadingRange } from './Heading.js';
import { LinkButton } from './Form/Button.js';
import useImgLoad from '../../hooks/universal/useImgLoad.js';

const Wrapper = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.RaisinBlack};
  color: ${({ theme }) => theme.colors.WhiteSmoke};
  gap: 15px;
  grid-template:
    'contents' 1fr
    'image' 1fr
    / 1fr;

  @media only screen and (min-width: 600px) {
    grid-template:
      'image contents' 1fr
      / 40% auto;
  }
`;

const Image = styled.div`
  grid-area: image;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 300px;
`;

const Contents = styled.div`
  grid-area: contents;
  display: grid;
  gap: 15px;
  padding: 15px;
`;

const CallToActionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const CallToAction = styled(LinkButton)`
  width: 300px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 0;
`;

export interface TwoColumnImageTextSectionProps
  extends Pick<
    TwoColumnImageTextSectionFields,
    'title' | 'linkLabel' | 'contents'
  > {
  link: string;
  image: string;
  titleHeadingLevel: HeadingRange;
}

export const TwoColumnImageTextSection = ({
  title,
  titleHeadingLevel,
  contents,
  link,
  linkLabel,
  image,
}: TwoColumnImageTextSectionProps) => {
  const src = useImgLoad(image, 1000);
  return (
    <Wrapper>
      <Image style={{ backgroundImage: `url(${src})` }} />
      <Contents>
        <Heading level={titleHeadingLevel}>{title}</Heading>
        <RichText document={contents} />
        <CallToActionWrapper>
          <CallToAction to={link}>{linkLabel}</CallToAction>
        </CallToActionWrapper>
      </Contents>
    </Wrapper>
  );
};
