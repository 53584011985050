import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Block,
  INLINES,
  Inline,
  MARKS,
  Document as RichTextDocument,
} from '@contentful/rich-text-types';
import React, { ReactNode } from 'react';
import {
  ContactMethodFields,
  PageEntryHyperlink,
  PageFields,
  ProductEntryHyperlink,
  ProductFields,
} from '../../../utils/exclusive/types.js';
import CFEntry from '../../../utils/universal/contentful/CFEntry.js';
import {
  Anchor,
  Document,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  OL,
  Paragraph,
  StyledLink,
  UL,
  Underline,
} from './NodesAndMarks.js';
import { ContactMethod } from '../ContactMethod.js';

export interface RichTextProps {
  document: RichTextDocument;
}

const options = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (node: Block | Inline, children: ReactNode) => (
      <Document>{children}</Document>
    ),
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
      <Paragraph>{children}</Paragraph>
    ),
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => (
      <H1>{children}</H1>
    ),
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => (
      <H2>{children}</H2>
    ),
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => (
      <H3>{children}</H3>
    ),
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => (
      <H4>{children}</H4>
    ),
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => (
      <H5>{children}</H5>
    ),
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => (
      <H6>{children}</H6>
    ),
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <UL>{children}</UL>
    ),
    [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <OL>{children}</OL>
    ),

    [INLINES.ENTRY_HYPERLINK]: (
      node: ProductEntryHyperlink | PageEntryHyperlink,
      children: ReactNode,
    ) => {
      const entry = new CFEntry<ProductFields | PageFields>(node.data.target);
      return (
        <StyledLink
          to={
            entry.contentType() === 'page'
              ? entry.field<string>('path')
              : `/products/${entry.field<string>('id')}`
          }
        >
          {children}
        </StyledLink>
      );
    },

    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      if (node.data.uri.startsWith('/')) {
        return <StyledLink to={node.data.uri}>{children}</StyledLink>;
      }
      return (
        <Anchor target="_blank" href={node.data.uri}>
          {children}
        </Anchor>
      );
    },

    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline, children: ReactNode) => {
      const entry = new CFEntry<ContactMethodFields>(node.data.target);
      return (
        <ContactMethod
          type={entry.field('type')}
          value={entry.field('value')}
        />
      );
    },
  },
  renderMark: {
    [MARKS.UNDERLINE]: (text: ReactNode) => <Underline>{text}</Underline>,
  },
  renderText: (text: string) => {
    return text;
  },
};

const RichText = ({ document }: RichTextProps) => (
  <>{documentToReactComponents(document, options as any)}</>
);

export default RichText;
