import React, {
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react';
import { styled, css } from 'styled-components';
import useProductGridFilter from '../../../hooks/exclusive/useProductGridFilter.js';
import { testIdGen } from '../../../utils/exclusive/misc.js';
import staticText from '../../../utils/exclusive/staticText.js';
import TextInput from '../../universal/Form/TextInput.js';

const Wrapper = styled.div`
  position: sticky;
  top: 65px;
  z-index: 3;
  gap: 15px;
  padding: 15px 0;
  margin: -15px 0;
`;

export interface ProductGridFilterProps {}

const ProductGridFilter = ({}: ProductGridFilterProps) => {
  const { onChange, search } = useProductGridFilter();
  const textInputRef = useRef<HTMLInputElement>();

  // Close mobile keyboard on submit
  const onKeyUp = useCallback((keyboardEvent) => {
    if (keyboardEvent.code === 'Enter') {
      textInputRef.current?.blur();
    }
  }, []) as KeyboardEventHandler<HTMLInputElement>;

  return (
    <Wrapper data-testid={testIdGen('product-grid-filter')}>
      <TextInput
        name="product-grid-search"
        placeholder={staticText({
          path: 'PRODUCTS.INDEX.SEARCH_INPUT.PLACEHOLDER',
        })}
        aria-label={staticText({
          path: 'PRODUCTS.INDEX.SEARCH_INPUT.ARIA_LABEL',
        })}
        data-testid={testIdGen('product-search-input')}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={search}
        spellCheck={false}
        ref={textInputRef as MutableRefObject<HTMLInputElement>}
      />
    </Wrapper>
  );
};

export default ProductGridFilter;
