import React from 'react';
import { styled, css } from 'styled-components';
import CloseButton from '../../../icon-buttons/CloseButton.js';
import { SideBarMenuParentNode, SideMenuItems } from '../../types.js';
import MenuSideBarSubMenuToggleButton, {
  MenuSideBarSubMenuExpandButtonProps,
} from './MenuSideBarSubMenuExpandButton.js';

const CloseButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export type FlattenedSideMenuItems = Map<SideBarMenuParentNode, SideMenuItems>;

const GoBackButton = styled<
  typeof MenuSideBarSubMenuToggleButton,
  { textPosition: MenuSideBarSubMenuExpandButtonProps['textPosition'] }
>(MenuSideBarSubMenuToggleButton)`
  ${({ theme }) => css`
    color: ${theme.colors.WhiteSmoke};
    border: 3px solid ${theme.colors.AlloyOrange};
    background-color: ${theme.colors.AlloyOrange};
    transition: background-color 300ms ease-in-out;
    padding: 5px 20px;
    font-size: 2.5rem;
    cursor: pointer;

    & svg {
      fill: ${theme.colors.WhiteSmoke};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: transparent;
      }
    }
  `};
`;

export interface MenuSideBarHeaderProps {
  onClose: () => void;
  showBackButton: boolean;
  onBackButtonClick: () => void;
  sideMenuGoBackButtonLabel: string;
  testIdGenerator: (testId: string) => string;
}

const MenuSideBarHeader = ({
  onClose,
  showBackButton,
  onBackButtonClick,
  sideMenuGoBackButtonLabel,
  testIdGenerator,
}: MenuSideBarHeaderProps) => {
  return (
    <>
      <CloseButtonSection>
        <CloseButton
          size={30}
          colorMode="secondary"
          onClick={onClose}
          data-testid={testIdGenerator('side-menu-close-button')}
        />
      </CloseButtonSection>
      {showBackButton && (
        <GoBackButton
          onClick={onBackButtonClick}
          data-testid={testIdGenerator('side-menu-back-button')}
          textPosition="before"
        >
          {sideMenuGoBackButtonLabel}
        </GoBackButton>
      )}
    </>
  );
};

export default MenuSideBarHeader;
