import { configureStore } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/dist/configureStore';
import { ErrorStoreState, errorReducer } from './error/reducer.js';
import { ProductsStoreState, ProductsReducer } from './products/reducer.js';
import { MenusStoreState, menusReducer } from './menus/reducer.js';
import { PagesStoreState, pagesReducer } from './pages/reducer.js';
import { RouterStoreState, routerReducer } from './router/reducer.js';
import { SettingsStoreState, settingsReducer } from './settings/reducer.js';

const baseStoreConfig = {
  reducer: {
    error: errorReducer,
    menus: menusReducer,
    products: ProductsReducer,
    pages: pagesReducer,
    settings: settingsReducer,
    router: routerReducer,
  },
};

export interface RootState {
  error: ErrorStoreState;
  menus: MenusStoreState;
  products: ProductsStoreState;
  pages: PagesStoreState;
  settings: SettingsStoreState;
  router: RouterStoreState;
}

export const store = configureStore<RootState>({
  ...baseStoreConfig,
  preloadedState: (globalThis as Record<string, any>).__PRELOADED_STATE__,
});

delete (globalThis as Record<string, any>).__PRELOADED_STATE__;

export const generateStore = (
  preloadedState?: RootState,
): EnhancedStore<RootState> => {
  return configureStore({
    ...baseStoreConfig,
    preloadedState,
  });
};

export type AppDispatch = typeof store.dispatch;
