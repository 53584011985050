import React from 'react';
import { Document as RichTextDocument } from '@contentful/rich-text-types';
import RichText from './RichText/index.js';
import FixedWidthWrapper from './FixedWidthWrapper.js';
import SectionBackground from './SectionBackground.js';

export interface RichTextSectionProps {
  document: RichTextDocument;
}

const RichTextSection = ({ document }: RichTextSectionProps) => {
  return (
    <SectionBackground coverPadded>
      <FixedWidthWrapper>
        <RichText document={document} />
      </FixedWidthWrapper>
    </SectionBackground>
  );
};

export default RichTextSection;
