import React from 'react';
import MenuButton from '../../icon-buttons/MenuButton.js';
import useMenuContext from '../context/useMenuContext.js';

const MenuExpandButton = () => {
  const { toggleMenuSidebarOpen, testIdGenerator } = useMenuContext();
  return (
    <MenuButton
      data-testid={testIdGenerator('menu-sidebar-expand-button')}
      size={50}
      onClick={toggleMenuSidebarOpen}
    />
  );
};

export default MenuExpandButton;
