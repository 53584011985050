import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProductByIdDispatcher } from '../../data/products/dispatchers.js';
import { AppDispatch, RootState } from '../../data/store.js';
import { ProductEntry } from '../../utils/exclusive/types.js';
import useCFEntry from './useCFEntry.js';
import { createFilterKey } from '../../utils/exclusive/products.js';

const fetchSelector =
  (releaseKey: 'releasedBefore' | 'releasedAfter', productId: string) =>
  (state: RootState) =>
    Boolean(state.products.order[createFilterKey({ [releaseKey]: productId })]);

const fetchNeighboringProduct =
  (releaseKey: 'releasedBefore' | 'releasedAfter', productId: string) =>
  (state: RootState) => {
    const neighboringProductId = state.products.order[
      createFilterKey({ [releaseKey]: productId })
    ]?.list.ids[0] as string | undefined;

    if (neighboringProductId) {
      return state.products.products[neighboringProductId];
    }

    return undefined;
  };

export const useProductData = () => {
  const dispatch = useDispatch() as AppDispatch;
  const { productId } = useParams();
  const createCfEntry = useCFEntry();

  if (!productId) {
    return undefined;
  }

  const product = useSelector<RootState, ProductEntry | undefined>((state) => {
    const { products } = state.products;
    return products[productId];
  });

  const hasFetchedPreviousRelease = useSelector<RootState, boolean>(
    fetchSelector('releasedBefore', productId),
  );

  const hasFetchedNextRelease = useSelector<RootState, boolean>(
    fetchSelector('releasedAfter', productId),
  );

  useEffect(() => {
    if (!product || !hasFetchedPreviousRelease || !hasFetchedNextRelease) {
      fetchProductByIdDispatcher(productId)(dispatch);
    }
  }, [product, productId]);

  if (product) {
    return createCfEntry(product);
  }

  return undefined;
};

export const useNeighboringProductData = (
  releaseKey: 'releasedBefore' | 'releasedAfter',
) => {
  const { productId } = useParams();
  const createCfEntry = useCFEntry();

  if (!productId) {
    return undefined;
  }

  const neighboringProduct = useSelector<RootState, ProductEntry | undefined>(
    fetchNeighboringProduct(releaseKey, productId),
  );

  if (neighboringProduct) {
    return createCfEntry(neighboringProduct);
  }

  return undefined;
};
