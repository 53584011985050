import { createContext } from 'react';

export type StaticTextGeneratorParams = Record<string, any>;

export type StaticTextGenerator = (
  params?: StaticTextGeneratorParams,
) => string;

export interface StaticTextConstants {
  [key: string]: StaticTextConstants | StaticTextGenerator | string;
}

export interface StaticTextContext {
  constants: StaticTextConstants;
}

export default createContext<StaticTextContext>({
  constants: {},
});
