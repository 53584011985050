import { StaticTextGeneratorParams } from '../components/universal/StaticText/staticTextContext.js';

const TEXT = {
  TITLE: 'Cascadia Fine Woodcraft',
  DEFAULT_TITLE: '%s | Cascadia Fine Woodcraft',
  HEADER: {
    SIDE_MENU_GO_BACK_BUTTON: `Go back`,
  },
  FOOTER: {
    CENTER_TEXT: () => {
      const currentYear = new Date().getFullYear().toString();
      return `© ${currentYear} Cascadia Fine Woodcraft`;
    },
  },
  PRODUCTS: {
    TITLE: ({ name }: StaticTextGeneratorParams) => `${name} | Products`,
    INDEX: {
      SEARCH_INPUT: {
        ARIA_LABEL: 'Search products by name',
        PLACEHOLDER: 'Search products by name...',
      },
      PREV_PAGE_BUTTON: 'Previous page',
      LOAD_MORE_BUTTON: 'Load more...',
    },
    DETAIL: {
      CAROUSEL: {
        PREV_BUTTON_ARIA_LABEL: 'Previous picture button',
        NEXT_BUTTON_ARIA_LABEL: 'Next picture button',
      },
      SEE_ALL_PRODUCTS_BUTTON: `See all products`,
    },
  },
};

export default TEXT;
