import React, { Children, ReactElement } from 'react';
import Section from './Section.js';

export interface SectionGeneratorProps {
  children: ReactElement | ReactElement[];
}

const SectionGenerator = ({ children }: SectionGeneratorProps) => (
  <>
    {children &&
      Children.map(children, (child) => (
        <Section key={child.key}>{child}</Section>
      ))}
  </>
);

export default SectionGenerator;
