import objectPath, { Path } from 'object-path';
import {
  StaticTextConstants,
  StaticTextGenerator,
  StaticTextGeneratorParams,
} from './staticTextContext.js';

const { get } = objectPath;

export interface StaticTextExtractorParams {
  constants: StaticTextConstants;
  path: Path;
  params?: StaticTextGeneratorParams;
}

const staticTextExtractor = ({
  constants,
  path,
  params,
}: StaticTextExtractorParams): string => {
  const result = get(constants, path, '') as StaticTextGenerator | string;

  if (typeof result === 'function') {
    return result(params);
  }

  if (typeof result === 'object' && result) {
    return result;
  }

  return result;
};

export default staticTextExtractor;
