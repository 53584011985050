import React from 'react';
import { css, styled } from 'styled-components';

export interface PageWrapperProps extends React.HTMLAttributes<HTMLElement> {
  padded?: boolean;
}

const PageWrapper = styled(({ padded = false, ...props }: PageWrapperProps) => (
  <div {...props} />
))`
  display: grid;
  gap: 15px;
  grid-template-rows: 1fr;
  margin-bottom: 15px;

  ${({ padded }) =>
    padded &&
    css`
      margin-left: 15px;
      margin-right: 15px;
    `}
`;

export default PageWrapper;
