import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createFilterKey } from '../../utils/exclusive/products.js';
import { ProductEntry } from '../../utils/exclusive/types.js';
import { fetchProductById, listProducts } from './apis.js';

export const fetchProductByIdAction = createAsyncThunk(
  'products/fetchProductById',
  fetchProductById,
);

export const listProductsAction = createAsyncThunk(
  'products/listProducts',
  listProducts,
);

export interface ProductsListResult {
  ids: string[];
  lastFetchedPage: number;
  nextPage?: number;
}

export type ProductsStoreState = {
  products: {
    [productId: string]: ProductEntry;
  };
  order: {
    [filterKey: string]: {
      filter: {
        search?: string;
        releasedBefore?: string;
        releasedAfter?: string;
      };
      list: ProductsListResult;
    };
  };
};

export const defaultListResult: () => ProductsListResult = () => ({
  ids: [],
  nextPage: 1,
  lastFetchedPage: 0,
});

const initialState: ProductsStoreState = {
  products: {},
  order: {},
};

const { reducer } = createSlice({
  name: 'Products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductByIdAction.fulfilled, (state, action) => {
        const product = action.payload.item;
        state.products[product.fields.id] = product;
      })
      .addCase(listProductsAction.fulfilled, (state, action) => {
        const {
          items: products,
          nextPage,
          page,
          search,
          releasedBefore,
          releasedAfter,
        } = action.payload;
        const filterKey = createFilterKey({
          search,
          releasedBefore,
          releasedAfter,
        });

        if (!(filterKey in state.order)) {
          state.order[filterKey] = {
            filter: {
              search,
              releasedBefore,
              releasedAfter,
            },
            list: defaultListResult(),
          };
        }

        const { list } = state.order[filterKey];

        if (page > list.lastFetchedPage) {
          products.forEach((product) => {
            state.products[product.fields.id] = product;
            list.ids.push(product.fields.id);
          });
          list.lastFetchedPage = page;
          list.nextPage = nextPage;
        }
      });
  },
});

export const ProductsReducer = reducer;
