import { fetchProductByIdAction, listProductsAction } from './reducer.js';
import { AppDispatch } from '../store.js';
import { FetchProductsByPageParams } from './apis.js';

export const fetchProductByIdDispatcher =
  (productId: string) => (dispatch: AppDispatch) =>
    Promise.all([
      dispatch(fetchProductByIdAction(productId)),
      dispatch(listProductsAction({ releasedBefore: productId })),
      dispatch(listProductsAction({ releasedAfter: productId })),
    ]);

export const listProductsDispatcher =
  (params: FetchProductsByPageParams) => (dispatch: AppDispatch) =>
    dispatch(listProductsAction(params));
