import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { fetchPageByPathDispatcher } from '../../data/pages/dispatchers.js';
import { AppDispatch, RootState } from '../../data/store.js';
import { PageEntry, PageFields } from '../../utils/exclusive/types.js';
import CFEntry from '../../utils/universal/contentful/CFEntry.js';
import useCFEntry from './useCFEntry.js';

const pageSelector = createSelector(
  [
    (
      state: RootState,
      pathname: string,
      createCfEntry: ReturnType<typeof useCFEntry>,
    ) => {
      return state.pages[pathname] as PageEntry | undefined;
    },
    (
      state: RootState,
      pathname: string,
      createCfEntry: ReturnType<typeof useCFEntry>,
    ) => {
      return createCfEntry;
    },
  ],
  (
    page: PageEntry | undefined,
    createCfEntry: ReturnType<typeof useCFEntry>,
  ) => {
    if (page) {
      return createCfEntry<PageFields>(page);
    }

    return undefined;
  },
);

const usePageData = () => {
  const dispatch = useDispatch() as AppDispatch;
  const location = useLocation();
  const createCfEntry = useCFEntry();

  const data = useSelector<RootState, CFEntry<PageFields> | undefined>(
    (state: RootState) => pageSelector(state, location.pathname, createCfEntry),
  );

  useEffect(() => {
    if (!data) {
      fetchPageByPathDispatcher(location.pathname)(dispatch);
    }
  }, [location, data]);

  return data;
};

export default usePageData;
