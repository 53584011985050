import React from 'react';
import { Anchor } from './RichText/NodesAndMarks.js';

export interface ContactMethodProps {
  type: 'phone' | 'email';
  value: string;
}

export const ContactMethod = ({ type, value }: ContactMethodProps) => {
  return (
    <Anchor href={`${type === 'phone' ? 'tel' : 'mailto'}:${value}`}>
      {type === 'phone'
        ? `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(6, 10)}`
        : value}
    </Anchor>
  );
};
