import React from 'react';
import { styled } from 'styled-components';
import ImageGrid from '../../universal/ImageGrid/index.js';
import ImageGridItem from '../../universal/ImageGrid/ImageGridItem.js';
import useProductListData from '../../../hooks/exclusive/useProductListData.js';
import { Helmet } from '../../universal/Helmet.js';
import { LinkButton } from '../../universal/Form/Button.js';
import SectionGenerator from '../../universal/SectionGenerator.js';
import { stringifyProductsQueryOptions } from '../../../utils/exclusive/products.js';
import ProductGridFilter from './ProductGridFilter.js';
import Loader from '../../universal/Loader.js';
import StaticText from '../../universal/StaticText/StaticText.js';
import { testIdGen } from '../../../utils/exclusive/misc.js';

const PreviousPageButton = styled(LinkButton)`
  margin-bottom: 15px;
`;

const ProductGrid = () => {
  const { page, products, nextPage, search } = useProductListData();

  const gridElementsList = products.map((product) => {
    const id = product.field<string>('id');
    return (
      <ImageGridItem
        key={id}
        title={<div>{product.field<string>('name')}</div>}
        image={product.assets('media').data?.[0]?.file()?.url ?? ''}
        url={`/products/${id}`}
        testId={testIdGen(`grid-item-${id}`)}
      />
    );
  });

  let prePageQuery: string | undefined;
  let nexPageQuery: string | undefined;

  if (page > 1) {
    prePageQuery = stringifyProductsQueryOptions({
      page: page - 1,
      search,
    });
  }

  if (nextPage) {
    nexPageQuery = stringifyProductsQueryOptions({
      page: nextPage,
      search,
    });

    if (products.length) {
      gridElementsList.push(
        <ImageGridItem
          key="load-more-grid-item"
          title={
            <div>
              <StaticText path="PRODUCTS.INDEX.LOAD_MORE_BUTTON" />
            </div>
          }
          url={`/products${nexPageQuery}`}
          testId={testIdGen('grid-item-loader-more')}
        />,
      );
    } else {
      gridElementsList.push(
        <ImageGridItem key="loader-grid-item" title={<Loader />} />,
      );
    }
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://cascadiafinewoodcraft.com/products${stringifyProductsQueryOptions(
            {
              page,
              search,
            },
          )}`}
        />
        {prePageQuery ? (
          <link
            rel="prev"
            href={`https://cascadiafinewoodcraft.com/products${prePageQuery}`}
          />
        ) : null}
        {nexPageQuery ? (
          <link
            rel="next"
            href={`https://cascadiafinewoodcraft.com/products${nexPageQuery}`}
          />
        ) : null}
      </Helmet>
      <ProductGridFilter />
      <noscript>
        {prePageQuery ? (
          <PreviousPageButton to={`/products${prePageQuery}`}>
            <StaticText path="PRODUCTS.INDEX.PREV_PAGE_BUTTON" />
          </PreviousPageButton>
        ) : null}
      </noscript>
      <SectionGenerator>
        <ImageGrid
          gap={15}
          minColumnWidth={250}
          testId={testIdGen('product-image-grid')}
        >
          {gridElementsList}
        </ImageGrid>
      </SectionGenerator>
    </>
  );
};

export default ProductGrid;
