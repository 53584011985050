import React from 'react';
import { css, styled } from 'styled-components';
import { useSettings } from '../../../data/settings/useSettings.js';
import footerLogo from '../../../media/footer-logo.svg?url';
import { testIdGen } from '../../../utils/exclusive/misc.js';
import { ContactMethod } from '../../universal/ContactMethod.js';
import FooterMenu from '../../universal/Menu/FooterMenu/FooterMenu.js';
import useMenuContext from '../../universal/Menu/context/useMenuContext.js';
import { Document, Paragraph } from '../../universal/RichText/NodesAndMarks.js';
import StaticText from '../../universal/StaticText/StaticText.js';

const StyledFooter = styled.footer`
  ${({ theme }) => {
    return css`
      display: grid;
      align-items: center;
      align-content: space-around;
      grid-template-areas:
        'logo'
        'contact'
        'menu'
        'copyright';
      background-color: ${theme.colors.RaisinBlack};
      width: 100%;
      height: 450px;
      padding: 15px;
      max-width: 100vw;
      position: absolute;
      bottom: 0;

      @media only screen and (min-width: 700px) {
        grid-template-areas:
          'logo contact menu'
          'copyright copyright copyright';
        grid-template-rows: 1fr 11px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        height: 200px;
      }
    `;
  }}
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-area: logo;
`;

const Logo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 150px;
  margin: auto;

  @media only screen and (min-width: 700px) {
    margin: 0;
  }
`;

const CopyrightText = styled.div`
  ${({ theme }) => {
    return css`
      color: ${theme.colors.AlloyOrange};
      font-size: 1rem;
      font-weight: 400;
      margin: auto;
      grid-area: copyright;
    `;
  }}
`;

const ContactWrapper = styled(Document)`
  ${({ theme }) => {
    return css`
      text-align: center;
      color: ${theme.colors.WhiteSmoke};
      grid-area: contact;
    `;
  }}
`;

const Footer = () => {
  const { mainContentFixedElementWidthOffset } = useMenuContext();
  const { loaded, location, phone, email } = useSettings();

  return (
    <StyledFooter
      style={{ width: `calc(100% - ${mainContentFixedElementWidthOffset}px)` }}
    >
      <LogoWrapper>
        <Logo data-testid={testIdGen('logo')} src={footerLogo} />
      </LogoWrapper>
      {loaded && (
        <ContactWrapper>
          <Paragraph>{location}</Paragraph>
          <Paragraph>
            <ContactMethod type="email" value={email} />
          </Paragraph>
          <Paragraph>
            <ContactMethod type="phone" value={phone} />
          </Paragraph>
        </ContactWrapper>
      )}
      <FooterMenu />
      <CopyrightText data-testid={testIdGen('footer-text')}>
        <StaticText path="FOOTER.CENTER_TEXT" />
      </CopyrightText>
    </StyledFooter>
  );
};

export default Footer;
