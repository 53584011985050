import * as HelmetPackage from 'react-helmet-async';
import { HelmetServerState } from 'react-helmet-async';

const { Helmet, HelmetProvider } = ((HelmetPackage as any)?.default ??
  HelmetPackage) as typeof HelmetPackage;

export { Helmet, HelmetProvider };

export interface HelmetContext {
  helmet?: HelmetServerState;
}
